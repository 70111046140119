// var en = require('../i18n/en.json');
// var es = require('../i18n/es.json');

// import * as dataen from '../i18n/en.json';
// import * as dataes from '../i18n/sn.json';



function setUserFormData() {
    if (userInfo.e) {
        $('#firstName').val(userInfo.fn);
        $('#lastName').val(userInfo.sn);
        $('#email').val(userInfo.e);
        $('#companyName').val(userInfo.com);
        $('#deliveryDate').val(userInfo.udd);
        $('#deliveryTime').val(userInfo.udt);
    }
}

function generateRandomPWD() {
    var length = 10,
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
        retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function redirectUser(url, flow) {
    // var userData = {
    //     s: 'wsuite',
    //     e: 'atulmeetme12@yopmail.com',
    //     p: 'Iar0sZUuon@Ws1',
    //     trackUrl : 'https://staging-track.wsuite.com/731570/579/quotationId/77/module-list/241'
    // }
    var userData = {
        s: userInfo.s,
        e: userInfo.e,
        p: userInfo.p,
        fn: userInfo.fn,
        sn: userInfo.sn,
        pn: userInfo.pn,
        dob: userInfo.dob,
        userLang: userInfo.userLang,
        trackUrl: url,
        flowType: flow
    }

    let userToken = btoa(JSON.stringify(userData));
    console.log('userData ==> ', userData);
    console.log('userToken ==> ', userToken);

    var finalUrl = generateServiceURL() + '/auth/autologin/' + userToken;
    // var finalUrl = 'http://localhost:4200/auth/autologin/'+userToken;
    console.log('Redirecting to LoginLibrary - Autologin Process ==> ', finalUrl);
    removeuserCookieData();
    removeCartData();
    window.location.href = finalUrl;

    // let iframeObj = document.getElementById("redirectIframe");
    // if(flow == "freelancer"){
    //     iframeObj = document.getElementById("redirectIframeFreelancer");
    // }
    // iframeObj.src = finalUrl;
    // iframeURLChange(iframeObj, function (changedURL) {
    //     console.log("URL changed:", changedURL);
    //     window.location.href = changedURL;
    // });
}

function checkOTPValidation(check = "all") {
    $("#OTPDetail-require-validation").hide();
    userInfo.OTPDetail.OTP = $('#OTPDetail1').val() + $('#OTPDetail2').val() + $('#OTPDetail3').val() + $('#OTPDetail4').val() + $('#OTPDetail5').val() + $('#OTPDetail6').val();
    var validated = false;
    if (check === "all" || check === "OTPDetail-require-validation") {
        if (!userInfo.OTPDetail.OTP) {
            $("#OTPDetail-require-validation").show();
            $('#OTPDetail-send-new').hide();
            $('#OTPDetail-valid-validation').hide();
            validated = true;
        } else {
            $("#OTPDetail-require-validation").hide();
            $('#OTPDetail-valid-validation').hide();
            $('#OTPDetail-send-new').hide();
        }
    }

    return validated;
}

function otpCheck() {
    var newDATA = {};
    if (checkOTPValidation()) {
        return false;
    } else {
        $("#sidePanelLoader").css('display', 'flex');
        $('#msg1').css('display', 'flex');
        $('#msg1_1').css('display', 'block');
        $('#msg1_2').css('display', 'none');

        console.log('userInfo-------------->', userInfo);

        newDATA = {
            "ChallengeName": "CUSTOM_CHALLENGE",
            "ChallengeResponses": { "USERNAME": userInfo.e, "ANSWER": userInfo.OTPDetail.OTP },
            "ClientId": mainObj.client_id,
            "Session": userInfo.OTPDetail.sessionID
        }


        $.ajax({
            url: mainObj.AMPLIFY_API,
            type: "POST",
            headers: amplifyHeader('CUSTOM_CHALLENGE'),
            dataType: "json",
            data: JSON.stringify(newDATA),
            success: function (data) {
                userInfo.OTPDetail.sessionID = data.Session;
                if (data.AuthenticationResult && data.AuthenticationResult.AccessToken !== undefined) {
                    setCookie('accessToken', data.AuthenticationResult.AccessToken);
                    setCookie('idToken', data.AuthenticationResult.IdToken);
                    setCookie('refreshToken', data.AuthenticationResult.RefreshToken);

                    getUserData();
                } else {
                    // toastr.error('Please enter valid OTP...');
                    $('#OTPDetail-valid-validation').show();
                    $('#OTPDetail-send-new').hide();
                    $("#sidePanelLoader").css('display', 'none');
                }


            },
            error: function (dataError) {
                $("#sidePanelLoader").css('display', 'none');
                $('#OTPDetail-send-new').show();
                // toastr.error(dataError.responseJSON.message);
                //  toastr.error("Please review your new OTP...");
                sendOTP();

            }
        });
    }

}

function getUserData() {
    userInfo.user_exist = true;
    jQuery.ajax({
        url: generateAPIURL() + '/autologin',
        dataType: 'json',
        data: JSON.stringify(userInfo),
        type: 'POST',
        headers: generateHeader(),
        success: function (userResponse) {
            console.log("userResponse ==> ", userResponse);
            if (userResponse && userResponse.data && userResponse.data.p) {
                userInfo.p = atob(userResponse.data.p);
                userInfo.user_exist = false;
                getERP()
            }
        }
    });
}

function getERP() {
    $.ajax({
        url: generateAPIURL() + '/getErp',
        type: "POST",
        headers: generateHeader(),
        dataType: "json",
        data: JSON.stringify({}),
        success: function (data) {
            console.log('GETERP-----', data.data.session_data.uid);

            if (data.data.session_data.uid) {

                setCookie('sid', data.data.sid);
                setCookie('uid', data.data.session_data.uid);
                setCookie('bid', data.data.session_data.company_id);
                setCookie('cid', data.data.session_data.partner_id);

                $('#msg1_1').css('display', 'none');
                $('#msg1_2').css('display', 'block');

                createCampaign();

            } else {
                console.log('ELSE GETERP-----', data.data.session_data.Message)
                $("#sidePanelLoader").css('display', 'none');
                toastr.error(data.data.session_data.Message);
            }

        },
        error: function (dataError) {
            console.log(dataError)
            $("#sidePanelLoader").css('display', 'none');
            toastr.error(dataError.responseJSON.message);
        }
    });

}

function checkUserData() {
    userInfo.fn = $('#firstName').val();
    userInfo.sn = $('#lastName').val();
    userInfo.e = $('#email').val();
    userInfo.com = $('#companyName').val();
    userInfo.p = generateRandomPWD() + '@Ws1';
    userInfo.pn = $('#com_phone').val();
    userInfo.countryCode = "+" + $('#countryCode').val();
    userInfo.dob = formatDate(new Date());
    userInfo.udd = $('#deliveryDate').val();
    userInfo.udt = $('#deliveryTime').val();
    // redirectUser();
    // return;
    if (checkValidation()) {
        return false;
    }

    if (checkuserlogin()) {
        console.log("user logged in...");
        $("#sidePanelLoader").css('display', 'flex');
        createCampaign();
    } else {
        console.log("do user login process...");
        $("#sidePanelLoader").css('display', 'flex');
        $('#msg1').css('display', 'flex');
        $('#msg1_1').css('display', 'block');
        $('#msg1_2').css('display', 'none');
        console.log('userInfo ==> ', userInfo);
        jQuery.ajax({
            url: generateAPIURL() + '/autologin',
            dataType: 'json',
            data: JSON.stringify(userInfo),
            type: 'POST',
            headers: generateHeader(),
            success: function (userResponse) {
                console.log("userResponse ==> ", userResponse);
                if (userResponse && userResponse.data && userResponse.data.tokens) {
                    setCookie('accessToken', userResponse.data.tokens.accessToken);
                    setCookie('idToken', userResponse.data.tokens.idToken);
                    setCookie('refreshToken', userResponse.data.tokens.refreshToken);
                    setCookie('sid', userResponse.data.tokens.sid);
                    setCookie('uid', userResponse.data.user_id);
                    setCookie('bid', userResponse.data.brand.id);
                    setCookie('cid', userResponse.data.brand.partner_id);
                    userInfo.brand_referance = mainObj.defaultBrandreference; //userResponse.data.brand.brand_referance;
                    $('#msg1_1').css('display', 'none');
                    $('#msg1_2').css('display', 'block');
                    createCampaign();
                } else {
                    alert('Error while creating user in Cognito');
                }
            },
            error: function (userError) {
                console.log('userError ==> ', userError);
                if (userError.status === 500 && userError.responseJSON.error_type == "timeout") {
                    webSocket(userError.responseJSON.data).then((userResponse) => {
                        console.log("userResponse ==>", userResponse);

                        const userData = JSON.parse(userResponse);
                        console.log("userData ==>", userData);
                        if (userData && userData.tokens) {
                            setCookie('accessToken', userData.tokens.accessToken);
                            setCookie('idToken', userData.tokens.idToken);
                            setCookie('refreshToken', userData.tokens.refreshToken);
                            setCookie('sid', userData.tokens.sid);
                            setCookie('uid', userData.user_id);
                            setCookie('bid', userData.brand.id);
                            setCookie('cid', userData.brand.partner_id);
                            userInfo.brand_referance = mainObj.defaultBrandreference; //userData.brand.brand_referance;
                            $('#msg1_1').css('display', 'none');
                            $('#msg1_2').css('display', 'block');
                            createCampaign();
                        } else {
                            toastr.error("Error while creating user");
                            $("#sidePanelLoader").css('display', 'none');
                        }
                    }, (err) => {
                        console.log("createOrder webSocket err ===>", err);
                        toastr.error("Error while creating user");
                        $("#sidePanelLoader").css('display', 'none');
                    });
                } else if (userError.responseJSON.message === "user_exist") {
                    // toastr.error('User already exist. Please try with another details.');

                    $("#sidePanelLoader").css('display', 'none');
                    $("#userData").css('display', 'none');
                    $("#alreadyRegister").css('display', 'block');
                    document.getElementById('btnResend').disabled = true;
                    sendOTP();
                    // $.ajax({
                    //     url: mainObj.AMPLIFY_API,
                    //     type: "POST",
                    //     headers: amplifyHeader('CUSTOM_AUTH'),
                    //     dataType: "json",
                    //     data: JSON.stringify({

                    //         "AuthFlow":"CUSTOM_AUTH",
                    //         "ClientId":mainObj.client_id,
                    //         "AuthParameters":{
                    //             "USERNAME":userInfo.e
                    //             },
                    //         "ClientMetadata":{}

                    //         }),
                    //     success: function (data) {
                    //         console.log(data.Session)
                    //         userInfo.OTPDetail.sessionID = data.Session;

                    //     },
                    //     error: function(dataError) {  console.log(dataError)          }
                    // });

                } else {
                    toastr.error(userError.responseJSON.message);
                    $("#sidePanelLoader").css('display', 'none');
                }
            }
        });
    }
}

function gerateDestinyURL() {
    var destinyUrl = mainObj.DESTINY_URL;
    var envPr = '';
    if (mainObj.env_type == 'stg-') {
        envPr = 'stage.';
    } else if (mainObj.env_type == 'dev-') {
        envPr = 'development-';
    } else {
        envPr = '';
    }
    destinyUrl = destinyUrl.replace('ENV', envPr);

    return destinyUrl;
}

function loadLanguage(lang) {
    // changeLanguage(lang);
}

// let lang =localStorage.getItem('language');
// changeLanguage(lang);





function changeLanguage(lang) {
    let arrLang = {
        en: {
            "HOME": "Home",
            "WORK": "Work",
            "ABOUT": "About",
            "PEOPLE": "People",
            "SERVICES": "Services",
            "WHAT_WE_DO": "What We Do",
            "KNOW_OUR_SOLUTIONS": "Know our solutions _",
            "OUR_SERVICES": "Our services",
            "WE_WORK_WITH_OUR_CLIENTS_TO_DEVELOP_DIGITAL": "We work with our clients to develop digital products and offer them the answers they need according to their needs. From research strategies to improve the customer experience with digital technologies aimed at promoting competitive advantages that generate value for the customer.",
            "EMAILS": "Emails",
            "BANNER": "Banner",
            "APP": "App",
            "LANDING_PAGE": "Landing Page",
            "WEBSITE": "Website",
            "E-COMMERCE": "E-Commerce",
            "SMS": "SMS",
            "PUSH_NOTIFICATION": "Push notification",
            "CONSULTING": "Consulting",
            "SUPPORT": "Support",
            "PIECE_OF_DIGITAL_ADVERTISING_THAT_MIXES_IMAGES": "Piece of digital advertising that mixes images, text and sometimes interactive content such as audio and animation.",
            "HERRAMIENTAS_DE_SOFTWARE_ESCRITAS_EN_DIFERENCES": "Software tools written in different programming languages, characterized by being useful, dynamic, easy to install and handle.",
            "ES_LA_ESENCIA_DE_LA_ESTRATEGIA_PARA_GENERAR_ENA": "It is the essence of the strategy to generate a better interaction, providing important information for the user.",
            "THEY_ARE_ONE_OF_THE_MOST_EFFECTIVE_WAYS_TO": "They are one of the most effective ways to present a company to the client, showing not only the essence but also the purpose of it.",
            "SON_UNA_DE_LAS_FORMAS_MAS_EFICACES_DE_PRESENTAR": "It allows the trade of different products and services from digital media, such as web pages, mobile applications and social networks.",
            "EL_USO_ESTE_SERVICIO_DARA_LUGAR_A_UN": "Using this service will result in an agile and fast contact with your current or potential clients.",
            "SON_ESAS_NOTIFICACIONS_QUE_APARENCEN_EN_LOS_DISPOSITIVE": "They are those notifications that appear on users’ devices when they visit a website, or suddenly on their mobile phones.",
            "DEFINA_EL_ALCANCE_FUNCIONAL_DE_SU": "Define functional scope of your project to plan project budget and timeline.",
            "ENTENDEMOS_QUE_UNA_TALLA_NO_SIRVE_PARA": "We understand one size doesn’t fit all. We can work with you to define and develop a custom solution that meets your needs.",

            "LEARN_MORE": "Learn more",
            "LEARN_MORE_GALLERY": "Learn more",
            "SEE_PROJECTS": "See Projects",
            "CONTACT_US_": "Contact us _",
            "WE_WANT_TO_WORK_WITH_YOU_AND_PROVIDE_YOU_WITH": "We want to work with you and provide you with the best advice so that together we can achieve your current goals and why not? We create many new ones.",
            "MAKE_SOMETHING_YOU_LOVE": "Make something you love __",
            "ABOUT_US": "About us",
            "WE_ARE_A_COMPANY_DEDICATED_TO_DIGITAL_PRODUCTION": "We are a company dedicated to digital production and development, with a focus on creating solutions that allow us to generate differential value in our clients’ products and leverage solid and lasting relationships between products and audiences.",
            "PRESENTS_A_SOLUTION_THAT_SAVES_TIME_AND_EFFORT": "Presents a solution that saves time and effort in the production of digital parts and projects.",
            "OUR_GROUP_OF_EXPERTS_WORK_ON_THE_DEVELOPMENT": "Our group of experts work on the development and management of projects aligned with the best technologies, strategic paths and exceptional user experiences.",
            "7_YEARS": "+7 years ",
            "EXPERIENCE": "experience_",
            "OPERATION_24_7": "Operation 24/7",
            "DEDICATED_TO_FINE_AND_DEVELOP_THE_BEST": "Dedicated to find and develop the best solutions for our clients.",
            "MIAMI": "Miami",
            "USA": "USA",
            "BOGOTA-MEDELLIN": "Bogotá - Medellín",
            "COLOMBIA": "Colombia",
            "AHMEDABAD": "Ahmedabad",
            "INDIA": "India",
            "BEHIND_THE_SCENES": "Behind the scenes __",
            "WE_COME_FROM_ALL_OVER_THE_WORD": "We come from all over the world,",
            "HAVE_A_DIVERSITY_OF_EXPERIENCE_AND": "have a diversity of experience and backgrounds, and practice a variety of disciplines. But we share a perpetual curiosity, and a belief that the things we make should be helping to bring about a more human future.",
            "PROJECT_MANAGER": "Project Manager",
            "RESPONIBLE_FOR_RECEIVING": "Responsible for receiving and understanding the purpose of each of the tasks and requirements delivered by the client. Also responsible for discussing deadlines and deliverables per week depending on load and equipment capacity. In addition, this profile will be responsible for enforcing the best practices and support and maintenance roles described in this document.",
            "FULLSTACK": "FullStack",
            "IT_WILL_BE_IN_CHARGE_OF": "It will be in charge of configuring the platform according to the architecture defined for the site. It configures the operation of the system following and implementing the best practices in code, together with the creation of the documentation of the modules and services created to carry out the project successfully and will ensure the correct operation and performance of the integrated solution for the client.",
            "FRONTND": "FrontEnd",
            "RESPONSIBLE_FOR_TRANSLATING_THE": "Responsible for translating the definitions of design and visual style made in previous stages to semantic HTML and CSS codes.",
            "QA": "QA",
            "IT_CONFIGURES_THE_OPERATION": "It configures the operation of the system following and implementing the best practices in code, together with the creation of the documentation of the modules and services created to carry out the project successfully and will ensure the correct operation and performance of the integrated solution for the client.",
            "UI": "UI",
            "THE__UI_DESIGNER": "The UI Designer is responsible for visually creating the product interface so that it goes according to the user’s experience. In addition, it creates interactive elements and cares that they look good on all platforms (mobile, tablet, web).",
            "UX": "UX",
            "A_USER_EXPERIENCE": "A User Experience Designer (UX Designer) is a professional who takes care that the perception and sensations that the use of a product or service leaves in people’s minds are optimal from any point of view: ergonomics, ease of use. use, efficiency, etc.",
            "COPY": "Copy",
            "PERSON_WHO_IS_IN_CHARGE_OF": "Person who is in charge of thinking about the creative strategy of a campaign, or the creative texts themselves that are used in said strategy",
            "BACKEND": "Backend",
            "THE_BACKEND_IS_THE_PART_OR_BRANCH": "The Backend is the part or branch of web development in charge of making all the logic of a page work. It consists of the set of actions that happen within a web, but that we cannot see",
            "DBA": "DBA",
            "THE_DATABASE_ADMINISTRATOR": "The database administrator (DBA) is the professional who manages information and communication technologies, being responsible for the technical, technological, scientific, business intelligence and legal aspects of databases.",
            "INFRASTRUCTURE": "Infrastructure",
            "ENGINEER_IN_THE_AREA_OF_INFORMATION": "The Data Analyst is a profile that thanks to the analysis and interpretation of data, can establish strategies and visualization formats in metrics to and interpretation of the data, can establish strategies and visualization formats in metrics for the understanding of business models. Therefore, he/she must know how to collect data, interpret them and at the same time analyze them statistically.",
            "ARCHITECT_OF_SOLUTIONS": "Architect of Solutions",
            "THIS_PROFILE_IS_IN_CHARGE_OF_DESIGNING": "This profile is in charge of designing, diagramming and making high-level decisions during the execution of the project. With extensive knowledge in software patterns, good development practices and software architecture.",
            "DATA_ENGINEER": "Data engineer",
            "WEB_MASTER": "Web Master",
            "ES_EL_ENCARGADO_DE_EVALUAR_Y_GES": "Es el encargado de evaluar y gestionar las actualizaciones de cada producto como páginas web, Google Analytics y códigos necesarios para medir el desempeño del Sitio o Apps.",
            "OUR_LEADERSHIP": "Our Leadership",
            "JUSN_EDUARDO_DELGADO": "Juan Eduardo Delgado",
            "PRODUCT_DIRECTOR": "Product Director",
            "JUAN_JOSE_ESCOBAR": "Juan José Escobar",
            "DEVELOPMENT_DIRECTOR": "Development Director",
            "SANDRA_CAMELO": "Sandra Camelo",
            "FINANCIAL_AND_ADMINISTATIVE_DIRECTOR": "Financial and Administrative Director",
            "LINA_CABRERA": "Lina Cabrera",
            "DESIGNEER_DIRECTOR": "Designer Director",
            "ANA_MARIA_ARANGO": "Ana María Arango",
            "COMMERCIAL_DIRECTOR": "Commercial Director",
            "FEDERICO_RAMIREZ": "Federico Ramirez",
            "DIRECTOR_DE_OPERACIONES_EXTERNO": "External Operations Director",
            "SEBASTIAN_ZUNIGA": "Sebastian Zúñiga",
            "MANAGEMENT_DIRECTOR": "Managment Director",
            "YOGESH_SHARMA": "Yogesh Sharma",
            "MANAGMENT_DIRECTOR_INDIA": "Managment Director India",
            "I_ACCEPT_THE": "I accept the",
            "TERMS_AND_CONDITIONS": "terms and conditions ",
            "SEND_MESSAGE": "Send message",
            "FREELANCER": "Freelancer",
            "SOCIAL_MEDIA": "Social media",
            "NAME": "Name",
            "EMAIL": "Email",
            "PHONE": "Phone",
            "CONTACT_AREA": "Contact-area",
            "MESSAGE": "Message",
            "ELASTIC_PRODUCTION": "Elastic production",
            "AUTOMATED_DIGITAL_PRODUCTION_WITH_THAT_WILL": "Automated digital production with AI that will offer you the best technological solutions.",
            "BUY_NOW": "Buy Now",
            "ONLINE": "Online",
            "QUOTATION": "Quotation",
            "PRODUCTION": "Production",
            "FAST": "Fast",
            "DELIVERY": "Delivery times",
            "SIMPLE_EASY_FAST": "Simple, easy, and fast",
            "MASSIVE_PRODUCTION_ASSETS": "Massive Production Assets",
            "WE_ARE_PROVIDING_A_DIGITAL": "We are providing a digital solution which includes a large amount of varieties belonging to different assets types.",
            "QUICK_OPTIONS": "Quick options",
            "ALL": "All",
            "SPECIAL_PROJECT": "Special projects",
            "DIGITAL_SOLUTION": "Digital Solutions",
            "WE_DELIVER_WEB_AND_MOBILE_APP": "We deliver web and mobile app development service to global businesses since 2014 with 100% project delivery success.",
            "WEBSITE": "Website",
            "BEING_A_LEADING_WEBSITE": "Being a leading website application development company, our experienced developers have unparalleled expertise in working with the latest web technologies to deliver custom websolutions.",
            "LANDINGS": "Landings",
            "WE_HAVE_AN_EXPERTISE_TO_DEVELOP_A": "We have an expertise to develop a landing page which is an important page published with a website to capture leads from online resources.",
            "APPS": "Apps",
            "FROM_B2B_OR_B2E_APPS": "From B2B or B2E apps for enterprises, small businesses, and startups, we have a proven track record of offering high-impact, result driven and engaging mobile application development services.",
            "WE_ARE_A_LEADING_ECOMMERCE_WEB": "We are a leading eCommerce web development company crafting beautiful web-based eCommerce solutions for ultimate online shopping experiences.",
            "SUPPORT": "Support",
            "WE_UNDERSTAND_ONE_SIZE_DOESNOT_FIT_ALL": "We understand one size doesn't fit all. We can work with you to define and develop a custom solution that meets your needs.",
            "CONSULTING": "Consulting",
            "DEFINE_FUNCTIONAL_SCROPE_OF_YOUR": "Define functional scope of your project to plan project budget and timeline.",
            "SEARCH_ASSETS": "Search Assets",
            "BUY_ALL": "Buy All",
            "OPTIONS": "options",
            "BUY_BUTTON": "Buy",
            "VIEW_DETAILS": "View Details",
            "TOTAL_QUANTITY": "Total Quantity",
            "ESTIMATED_TIME_AND_COST": "Estimated Time & Cost",
            "VIEW_ALL": "View all",
            "STARTING_FROM": "Starting from",

            "DETAILS": "Details",
            "ASSET_SUMMARY": "Asset Summary",
            "PAYMENT": "Payment",
            "PERSONAL_DETAILS": "Personal Details",
            "NAME": "Name",
            "PLEASE_PROVIDE_NAME": "Please provide first name",
            "YOUR_FIRST_NAME": "Your first name",
            "LAST_NAME": "Last Name",
            "PLEASE_PROVIDE_LAST_NAME": "Please provide last name",
            "YOUR_LAST_NAME": "Your last name",
            "PLEASE_PROVIDE_EMAIL": "Please provide email",
            "PLEASE_PROVIDE_VALID_EMAIL": "Please provide valid email",
            "NAME_GEMAIL": "name@gmail.com",
            "COMPANY_NAME": "Company Name",
            "PLEASE_PROVIDE_COMPANY_NAME": "Please provide company name",
            "YOUR_COMPANY_NAME": "Your company name",
            "EXPECTED_DELIVERY_DATE": "Expected Delivery Date",
            "PLEASE_PROVIDE_DATE": "Please provide date",
            "ACCEPT_AND_CONTINUE": "Accept and Continue",
            "ASSET_SUMMARY": "Asset Summary",
            "PRODUCT": "PRODUCT",
            "STATUS": "STATUS",
            "START_DATE": "START DATE",
            "FINAL_DATE": "FINAL DATE",
            "PRICE": "PRICE( In USD)",
            "PAYMENT_DETAILS": "Payment Details",
            "NAME_ON_CARD": "Name On Card",
            "PLEASE_PROVIDE_CARD_NAME": "Please provide card name",
            "YOUR_NAME": "YOUR_NAME",
            "CREDIT_CARD_NUMBER": "Credit Card Number",
            "PLEASE_PROVIDE_YOUR_CARD_NUMBER": "Please provide your card number",
            "PLEASE_PROVIDE_VALID_CARD_NUMBER": "Please provide valid card number",
            "YOUR_CREDIT_CARD_NUMBER": "Your credit card number",
            "EXPIRATION": "Expiration",
            "PLEASE_PROVIDE_MM_YY": "Please provide MM/YY",
            "PLEASE_PROVIDE_VALID_EXPIRATION": "Please provide valid expiration",
            "MM_YY": "MM/YY",
            "CVV": "CVV",
            "PLEASE_PROVIDE_CARD_CVV": "Please provide card CVV",
            "PLEASE_PROVIDE_VALID_CVV": "Please provide valid CVV",
            "YOUR_CVV": "Your CVV",
            "THANK_YOU": "Thank You!",
            "OTP_VERIFICATION": "OTP Verification",
            "YOU_ARE_ALREADY_A_REDISTERED_USER": "You are already a registered user. We have sent an OTP to your registered email address. Please enter the 6 digit otp code to continue.",
            "ENTER_6_DIGIT_OTP": "Enter 6 Digit OTP",
            "PLEASE_PROVIDE_YOUR_OTP_CODE": "Please provide your otp code...",
            "PLEASE_PROVIDE_YOUR_VALID_OTP_CODE": "Please provide your valid otp code...",
            "WE_HAVE_SENT_YOU_NEW_OTP": "We have sent you new otp code please use it...",
            "CONTINUE": "Continue",
            "RESEND_OTP": "Resend OTP",
            "WE_ARE_VALIDATING_YOUR_USER": "We are validating your user...",
            "WE_ARE_CREATING_YOUR_PROJECT": "We are creating your project...",
            "WE_ARE_CREATING_YOUR_QUOTATION": "We are creating your quotation...",
            "WE_ARE_CREATING_TASKS_FOR_YOUR_QUOTATION": "We are creating tasks for your quotation...",
            "ASSET_DETAILS": "Asset Details",
            "QTY": "QTY",
            "EFFORT_IN_HOURS": "EFFORT (In Hours)",

            "BANNER_WORK": "Work",
            "MAKE_SOMETHINNG_YOU_LOVE": "Make something you love __",
            "BANNERS": "Banners",
            "E_EMAILS": "E-mails",
            "E_EMAIL_SEND": "E-mail send",
            "E_EMAIL_HTML": "E-mail HTML",
            "POS_MATERIALS": "POS Materials",
            "SOCIAL_CONTENT": "Social Content",
            "ECOMMERCE": "Ecommerce",
            "AMBER_CLOTHING": "Amber Clothing",
            "CLOTHES_AND_ACCESSORIES_FOR_YOUR_BABY": "Clothes and accessories for your baby.",
            "PRINCE_OF_WALES": "Prince of Wales",
            "CLUB": "Club",
            "IT_IS_A_PRESTIGIOUS_CLUB_LOCATED_IN_SANTIAGO_DE": "It is a prestigious club located in Santiago de Chile, they define it as an incomparable place where the whole family can enjoy and live first-rate experiences.",
            "VOD_BANCOLOMBIA": "VOD - Bancolombia",
            "IT_IS_A_MANAGEMENT_PLATFORM_THAT__ALLOWS_YOU_TO_UPLOAD": "It is a management platform that allows you to upload audiovisual content for Bancolombia.",
            "E_MAIL": "E-mail",
            "RAPPI": "Rappi",
            "IT_IS_A_PLATFORM_THAT_HANDLES_MASS": "It is a platform that handles mass communication. They design a large flow of emails for their clients.",
            "RAPPI_AND_MORE": "Rappi and more",
            "PUT_THE_FINISHING_TOUCH_ON_YOUR_FAVORITE": "Put the finishing touch on your favorite appetizers or let your imagination run wild with the recipe book from A to Z.",
            "LANDING": "Landing",
            "CERVEZA": "Cerveza",
            "ANDINA": "Andina",
            "IT_IS_A_PERSTIGIOUS_CLUB_LOCATED_IN_SANTIAGGO_DE": "It is a prestigious club located in Santiago de Chile, they define it as an incomparable place where the whole family can enjoy and live first-rate experiences.",

            "ELASTIC_PRODUCTION": "Elastic production",
            "WE_HAVE_OFFICES_AND_TEAMS_THE_WORLD_SERVING": "We have offices & teams around the world serving different markets",
            "OUR_VISION_HAS_LED_DESTINY_TO_BECOME_A_TOP_IT": "Our vision has led Destiny to become a top IT company for delivering various industry-led mobility solutions. The goal is to empower clients and businesses by creating new possibilities leveraging the technologies of today and tomorrow with the utmost quality, satisfaction, and transparency.",
            "CLIENT_CENTRIC_APPROACH": "Client-centric Approach",
            "FOR_ANY_BUSINESS_CUSTOMERS_ARE_ALWAYS_AT_THE_CENTER": "For any business, customers are always at the center. Being a leading web and mobile app development company, our definition goes beyond our direct customers. We always start from where you are with your ideas and we think from YOUR end customers' perspectives, their pain areas and devise a solution that solves core problems to benefit your business.",
            "EFFECTIVE_COLLABORATION": "Effective Collaboration",
            "COMMUNICATION_IS_THE_KEY__WE_TENF_TO_OVER": "Communication is the Key. We tend to over-communicate at times. Although it sounds overwhelming, eventually it works wonders for our client business. When our development team collaborates with our clients sitting a thousand miles away, it's apparent that we stay in touch with platforms and mediums that allow effectiveness, transparency, and accuracy.",
            "QUALITY_DELIVER_IN_TIME": "Quality Deliver in time",
            "WE_HAVE_A_GREAT_SET_OF_INTERNAL_FRAMEWORKS": "We have a great set of internal frameworks with best-in-class infrastructure that enables us to deliver solutions with superior quality, at all times. All these years, we have learned how to prevent failures and replicate success. We don't just brag about quality. We define and deliver it in time.",
            "RESULT_ORIENTED_APPROACH": "Result-oriented Approach",
            "THE_ROOTS_OF_OUR_SUCCESS_ARE_CONNECTED_TO_OUR_VISION": " The roots of our success are connected to our vision and execution of it. Starting from the recruitment to the quality deliverables, we keep our values intact. We hire passionate humans who have that fire burning in them. People who really want to work with us and understand the importance of client expectations to deliver quality results.",

            "INFORMATION": "Information",
            "SKILLS": "Skills",
            "PROFILE_PHOTO": "Profile Photo",
            "UPLOAD_AN_IMAGE": "Upload an image",
            "REMOVE_PHOTO": "Remove Photo",
            "PREFRRED_SIZE_250_BY_250_PIXEL": "* Preferred size 250 by 250 pixel (.png or jpg)",
            "PLEASE_PROVIDE_YOUR_PROFILE_PHOTO": "Please provide your profile photo",
            "PLEASE_PROVIDE_PROPER_SIZE_PROFILE_PHOTO": "Please provide proper size profile photo.",
            "FIRST_NAME": "First Name",
            "PLEASE_PROVIDE_FIRST_NAME": "Please provide first name",
            "COUNTRY": "Country",
            "PLEASE_SELECT_COUNTRY": "Please select country",
            "PLEASE_PROVIDE_COUNTRY": "Please provide country",
            "STATE": "State",
            "PLEASE_SELECT_STATE": "Please select state",
            "PLEASE_PROVIDE_STATE": "Please provide state",
            "CONTECT_NUMBER": "Contact Number",
            "PLEASE_PROVIDE_CONTACT_NUMBER": "Please provide contact number",
            "PLEASE_PROVIDE_VALID_CONTACT_NUMBER": "Please provide valid contact number",
            "JOB_DESCRIPTION": "Job Description",
            "PLEASE_PROVIDE_YOUR_JOB_DESCRIPTION": "Please provide your job description",
            "TOTAL_YEARS_OF_EXPERIENCE": "Total Years Of Experience",
            "SELECT_YOUR_TOTAL_EXPERIENCE": "Select your total experience",
            "PLEASE_PROVIDE_YOUR_EXPERIENCE": "Please provide your experience",
            "PROFILE": "Profile",
            "SELECT_YOUR_PROFILE": "Select your profile",
            "PLEASE_PROVIDE_YOUR_PROFILE": "Please provide your profile",
            "SELECT_YOUR_SKILL": "Select your skill",
            "PLEASE_PROVIDE_YOUR_SKILL": "Please provide your skill",
            "RATE_YOUR_SKILLS": "Rate Your Skills",
            "UPLOAD_PORTFOLIO": "Upload Portfolio",
            "UPLOAD": "Upload",
            "DRAG_AND_DROP_FILES_HERE": "Drag and Drop Files Here",
            "PLEASE_PROVIDE_YOUR_PORTFOLIO": "Please provide your portfolio",
            "SAVE_AND_REGISTER": "Save & Register",

            "KNOW_OUR": "Know our",
            "SOLUTIONS": "solutions",
            "WHAT_IS_APP": "What is an App?",
            "APPS_ARE_NOTHING_MORE_THEN_SOFTWARE_TOOLS_WRITTEN_IN": "Apps are nothing more than software tools written in different programming languages, characterized by being useful, dynamic, easy to install and use.",
            "BOOT_YOUR_MARKETING_STRATEGIES_BY_CREEATINGYOUR_OWN": " Boost your marketing strategies by creating your own mobile application. By having an innovative proposal for your company, you will create the opportunity for your clients to interact in a more dynamic and attractive way with your company.",
            "MAKE_A_DIFFERENCE_AND_BECOME_A_REFERENCE": "Make a difference and become a reference!",
            "APPS_MADE_BY_US": "Apps made by us.",
            "WE_CREATE_APPS_FOR_YOU": "We create Apps for you!",
            "QUOTE_YOUR_APPS": "Quote your APP",
            "START_NOW": "Start now!",
            "MARKETING_APPS": "Marketing Apps",
            "AT_DESTINY_WE_HAVE_A_TEAM_OF_EXPERT_DEVELOPERS": "At destiny we have a team of expert developers in digital products who use a user-based approach, thus guaranteeing the usability and ease of user interaction with your digital products, creating a unique and innovative experience that will leave a mark.",
            "TESTING_QA": "Testing / QA",
            "WE_OFFER_YOU_A_CONSTANT_PROCESS_WHERE_WE_WILL": "We offer you a constant process where we will guarantee that your product works efficiently and with high quality standards that will minimize adjustments at the time of production.",
            "ENTERPRISE_SOLUTIONS": "Enterprise Solutions",
            "WE_WORK_HAND_IN_WITH_OUE_CLIENTS_IN_THE": "We work hand in hand with our clients in the development of personalized software, perfecting the internal processes of companies in areas such as: sales, marketing, operations, management, among others.",
            "APP_TYPES": "App types",
            "FOR_US_IT_IS_IMPORTANT_TO_PROVIDE_APPS": "For us it is important to provide Apps that fit the client’s needs.",
            "APPS_AND_DATA": "Apps and data analysis!",
            "MAINTAINING_A_STATISTICAL_ANALYSIS_CAN_GIVE": "Maintaining a statistical analysis can give you the opportunity to easily find areas for improvement and strengthen those that already work. Get to know your company and take it to another level!",
            "APPS_FOR_E_COMMERCE": "Apps for E-commerce!",
            "IT_IS_IMPORTANT_TO_KEEP_YOUR_USERS": "It is important to keep your users updated about your company through your APP. Give your company the opportunity to grow and win a greater number of potential customers!",
            "APPS_FOR_ENTREPREEURS": "Apps for entrepreneurs!",
            "THE_WORLD_BELONGS_TO_THOSE_WHO_UNDERTAKE": "The world belongs to those who undertake and trust their creativity. Thousands of people bet on the business world through its StartUP, surpassing 1,000 million dollars in valuation. Dare to fulfill your dreams!",
            "APPS_FOR_COMPANIES_OR_B2B": "Apps for companies or B2B services!",
            "WE_KNOW_THAT_EACH_COMPANY_HAS_DIFFERENT": "We know that each company has different needs and areas of opportunity to deal with, that is why we offer completely personalized advice focused on each of the company’s goals for the development of the APP, thus achieving the dynamization of the process with the objective of optimize the sale of the company’s products and / or services.",
            "APPS_AND_MEDIA": "Apps and media!",
            "STAY_INFORMED": "Stay informed. May important news always go with you. Can you imagine being able to promote all your informative content through an APP? Do not lose this chance!",
            "APPS_FOR_RESTAURENTS": "Apps for restaurants!",
            "THE_RESTAURENT_INDUSTRY_HAS_ALREADY": "The restaurant industry has already joined the digital age. Don’t be left behind! Give your business the opportunity to optimize its customer service.",
            "DO_YOU_HAVE_ANY_DOUBT": "Do you have any doubt?",
            "CONTACT_US": "Contact us",
            "CATEGORIES": "Categories",
            "EXPLORE_AND_LEARN_ABOUT_THE_DIFFERENT": "Explore and learn about the different",
            "SERVICES_WE_OFFER": "services we offer.",
            "CLOSER_COMMUNICATION_STRATEGIES_WITH_CUSTOMERS": "Closer communication strategies with customers, direct and assertive contact with current users or future prospects.",
            "PIECE_OF_DIGITAL_ADVERTISING_THAT_MIXES": "Piece of digital advertising that mixes images, text and sometimes interactive content such as audio and animation.",
            "SOFTWARE_TOOLS_WRITTEN_IN_DIFFERENT_PROGRAMMING": "Software tools written in different programming languages, characterized by being useful, dynamic, easy to install and handle.",
            "IT_IS_THE_ESSENCE__TO_GENERATE_A_BETTER": "It is the essence of the strategy to generate a better interaction, providing important information for the user.",
            "THEY_ARE_ONE_OF_THE_MOST_EFFETIVE_WAYS": "They are one of the most effective ways to present a company to the client, showing not only the essence but also the purpose of it.",
            "IT_ALLOWS_THE_TRADE_OF_DEFFERENT_PRODUCTS_AND": "It allows the trade of different products and services from digital media, such as web pages, mobile applications and social networks.",
            "USING_THIS_SERVICE_WILL_RESULT_IN_AN": "Using this service will result in an agile and fast contact with your current or potential clients.",
            "THEY_ARE_THOSE_NOTIFICATIONS_THAT_APPEAR_ON_USERS": "They are those notifications that appear on users’ devices when they visit a website, or suddenly on their mobile phones.",
            "DEFINE_FUNCTIONAL_SCOPE_OF_YOUR_PROJECT": "Define functional scope of your project to plan project budget and timeline.",
            "WE_UNDERSTAND_ONE_SIZE_DOESNOT_FIT_ALL": "We understand one size doesn’t fit all. We can work with you to define and develop a custom solution that meets your needs.",
            "SEE_MORE": "See more",

            "WHAT_IS_AN_SMS": "What is an SMS?",
            "AN_SMS_IS_A_SERVICE_AVAOLABLE_ON": "An SMS is a service available on mobile phones that allows the sending of short messages between cell phones.",
            "USING_THIS_SERVICE_WILL_RESULT": "Using this service will result in an agile and fast contact with your current or potential clients. Start using it as a strategy now!",
            "GUARANTEED_RESULTS": "Guaranteed Results!",
            "SMS_IS_A_GOOD_AND_EXCELLENT_": "SMS is a good and excellent distribution channel when it comes to wanting to deliver a short-term message, they have one of the highest open and participation rates among all the channels used in the market.",
            "WHY_USE_SMS_AN_A_STRATEGY": "Why use SMS as a strategy?",
            "IF_YOU_WANT_TO_INCREASE": "If you want to increase sales and visits in a fast and constant way.",
            "SMS_COUPONS_HAVE_BEEN_SHOWN": "SMS coupons have been shown to be very effective in driving sales and increasing revenue. Customers redeem coupons sent by text message 10 times more than other coupons, sent through other means.",
            "THE_CLICK_RATE_FOR_SMS": "The click rate for SMS URLs is 19%, which places it among the highest compared to other media.",
            "USE_SMS_TO_LINK_ONLINE": "Use SMS to link online and offline by sending offers, and thus achieve in-person visits to your business.",
            "IT_IS_A_GREAT_SOURCE_OF": "It is a great source of loyalty and generation of important leads for the company.",
            "THE_NUMBERS_DONT__LIE": "The numbers don’t lie!",
            "OF_SMS_ARE_READ_BY_THE_USER": "Of SMS are read by the user.",
            "OF_SMS_ARE_READ_IN_THE_FIRST_3_MINUES": "Of SMS are read in the first 3 minutes of being received.",
            "OF_USERS_USE_SMS_CONFIRM_THAT_IT_IS_AN": "Of users use SMS and confirm that it is an effective means.",
            "OF_PEOPLE_USE_CELL_PHONES": "Of people use cell phones.",
            "BET_ON_FAST_AND_EFFECTIVE_COMMINICATION": "Bet on fast and effective communication with the user",
            "YOU_WILL_ALWAYS_ACHIEVE_THE_BEST_RESULT": "You will always achieve the best results in the shortest estimated time.",

            "WHAT_IS_A_BANNER": "What is a Banner?",
            "A_BANNER_IS_A_PIECE_OF_DIGITAL_ADVERTING": "A banner is a piece of digital advertising that mixes images, text and sometimes interactive content such as audio and animation, this is included in web pages to make the image of a company or campaign more attractive and visible. Usually the banner aims to invite the user to interact through a click that will direct the person to a promotional page.",
            "BANNER_ARE_ONR_OF_THE_MOST_USED_ELEMENTS": "Banners are one of the most used elements for the monetization of a web page through advertising, which is why it is usual to see them on portals with high traffic or that are specialized in some specific content.",
            "WHAT_SHOULD_YOUR_BANNER_HAVE": "What should your banner have?",
            "FOR_THE_PIECE_TO_BE_COMPLETELY_EFFICIENT": "For the piece to be completely efficient and<br> effective, it must contain some elements such as:",
            "CELL_TO_ACTION": "Call to action",
            "CTA": "(CTA)",
            "BANNER_DIMENSIONS": "Banner dimensions",
            "KEEP_USER": "Keep user",
            "CAPTIVE": "captive",
            "ORIGINAL_AND_CREATIVE": "Original and creative content and image",
            "CONTENT_AND_IMAGE": "content and image",
            "URL": "URL",
            "IDENTIFICATION": "identification",
            "SHOWINESS": "Showiness",
            "AND_ELEGANCE": "and elegance",
            "SHAPES": "shapes",
            "BANNER_SHAPES": "Banner shapes.",
            "MOVEMENT": "Movement",
            "AND_ACTION": "and action",
            "MOVEMENT_AND_ACTION": "Movement and action",
            "A_GOOD": "A good",
            "POSITION": "position",
            "IT_SHOULD_LOOK_PROFESSIONAL": "It should look professional",
            "IT_SHOULD_LOOK_PROFESSIONAL_AND_TRUSTWORTH": "It should look professional and trustworth",
            "AND_TRUSTWORTH": "and trustworth",
            "TYPE_OF_BANNERS": "Types of banners",
            "STEALER": "Stealer",
            "DONT_BE_FOOLED_BY_ITS_NAME_THIS": "Don’t be fooled by its name, this format always seeks to integrate into the design and style of the page, thus maintaining its harmony without being invasive. Its format can be horizontal or vertical and its size can vary between 300 x 250 pixels up to 300 x 600 pixels.",
            "RECTANGULAR": "Rectangular",
            "IT_IS_A_FORMAT_WITH_A": "It is a format with a horizontal orientation, and it is commonly the most used. On many occasions this type of banner is located in the upper third of the page.",
            "THERE_ARE_THREE_TYPES": "There are three types:",
            "THE_MEGABANNER": "The megabanner: This can have more than 900 x 90 pixels.",
            "THE_STANDARD_BANNER": "The standard banner: This can be 468 x 60 pixels",
            "THE_MIDDLE_BANNER": "The middle banner: This can be 234 x 60 or 234 x 90 pixels.",
            "SKYSCRAPER": "Skyscraper",
            "THIS_BANNER_HAS_A_RECTANGULAR_FORMAT_WITH": "This banner has a rectangular format with a vertical orientation and is usually located in the side columns of a web page. Its size is 100 x 600 or 120 x 700 pixels.",
            "EXPANDABLE": "Expandable:",
            "THESE_HAVE_SIMILAR_FORMATES_BUT_HAVE_THE": "These have similar formats, but have the ability to expand on the screen so that they occupy a large part of it, this option varies depending on the medium where the banner is located. Typically, this type of part is displayed when hovering the mouse pointer or entering the site. Being so invasive, it must have a close button visible enough for the user, this is recommended by the IAB.",
            "INTERSTITIAL": "Interstitial",
            "THIS_BANNER_FILLS_THE_ENTIRE_BROWSER": "This banner fills the entire browser screen. Its effectiveness is of a very high percentage since it captures the user’s attention immediately, but being such an invasive format it is recommended to give it a controlled use.",
            "LAYER_OR_LAYER": "Layer or layer:",
            "THIS_FORMAT_CONTAINS_AN_ANIMATED_IMAGE": "This format contains an animated image in constant movement on the content of the page, and although it has great results when trying to attract attention and generate CTR, it is highly intrusive and must be handled in a moderate way.",
            "BANNER_MADE_BY_US": "Banner made by us.",
            "LEAVE_YOUR_FIRST_IMPRESSION_IN_THE_HANDS": " Leave your first impression in the hands of experts ",
            "AND_DONT_MISS_THE_OPPORTUNITY": " and don’t miss the opportunity to grow your business.",

            "WHAT_IS_AN_EMAIL": "What is an Email?",
            "AN_EMAIL_IS_ONE__OF_THE_closest": "An Email is one of the closest communication strategies with customers, direct and assertive contact with current users or future prospects is a great opportunity to guarantee loyalty, do not miss the opportunity to guarantee the continuity of your subscribers and thus be able to provide constant and updated information on each of the products and services offered by your company.",
            "EMAIL_MARKETING_AS_A": "Email marketing as a strategy!",
            "DO_NOT_LOSE_YOUR_HEAD_TRYING": "Do not lose your head trying to get the messages to your clients, our experts in the area will be directly in charge of getting the emails to the inbox of each of the users, giving constant support and advice, thus adapting the strategies and delivery capacity focused on each of the client’s needs.",
            "DESTINY_OFFERS_YOU_A_CPMLETE": "Destiny offers you a complete consultancy to improve the campaigns, the management of your lists, the scope of delivery of the emails and of course the general marketing strategy to obtain the best results in your business.",
            "CUSTOMIZE_YOUR_CAMPAIGNS": "Customize your campaigns!",
            "WE_WILL_CONDUCT_A_COMPLETE_STUDY_OF_YOUR": "We will conduct a complete study of your current email program, thus creating the best starting point for our experts to improve performance and increase lead acquisition, ROI, or the most important performance indicators for your business.",
            "EMAIL_MADE_BY_US": "Email made by us.",
            "VER_MAS": "Ver más",
            "EVERYTHING_ENTERS": "Everything enters",
            "THROGH_THE_EYES": "through the eyes!",
            "WE_HAVE_THE_BEST_TEAM_OFDESIGNERS": "We have the best team of designers and content creators so that your emails always look attractive and professional, thus creating a more effective and attractive way to deliver your messages to the user.",
            "THE_NUMBER_DONT_LIE": "The numbers don’t lie!",
            "WE_WORK_WITH_OUR_CLIENTS_TO_DEVELOP": "We work with our clients to develop digital products and offer them the answers they need according to their needs.",
            "FROM_RESEARCH_TO_IMPROVE_THE": "From research strategies to improve the customer experience with digital technologies aimed at promoting competitive",
            "ADVANTAGE_THAT_GENERATE_VALUE_FOR": "advantages that generate value for the customer.",
            "AT_DESTINY_WE_REACHED_90_DEVIVERABILITY": " At Destiny we reached 90% deliverability, thus beating the industry average that currently stands at 85%.",
            "WE_HAVE_AN_AVARAGE_MONTHLY_VOLUME": " We have an average monthly volume of 15k only from our deliverability service clients.",
            "THE_AVARAGE_BOUNCE_RATE_OF_OUR": "The average bounce rate of our current clients is 1%, exceeding 2% of the average rate that the industry has today.",
            "WE_DELIVER_YOU": "We deliver you",
            "WELCOME_EMAIL_FOR_NEW_SUBSCRIBERS": "Welcome email for new subscribers.",
            "A_SERIES_OF_ACTIVATED_EMAILS": "A series of activated emails.",
            "THE_SEGMENTATION_OD": "The segmentation of your contacts.",
            "REPLYING_OF_EMAILS": "Replying of emails which have not been opened.",
            "POWERFUL_LIST_BUILDING": "Powerful list building tools",
            "ALL_OF_THIS_THROUGH_OUR_SIGNIFICANT": "All of this through our significant experience at",
            "AT_DESTINY_WE_WANT_TO_CREATE_YOUR": "At Destiny we want to create your",
            "MESSAGE_AND_BE_PART": "messages and be part of your work team.",
            "QUOTE_YOUR_EMAIL": "Quote your email",

            "DO_YOU_NEED_A_LANDING_PAGE": "Do you need a Landing Page?",
            "THE_SUCCESS_OF_YOUR_BUSINESS": "The success of your business is closely linked to the amount of Leads and effective sales you can generate. A Landing Page is an essential part of the strategy to achieve this, generating better interaction and providing a broad database for the company. A landing page is a strategy in which users arrive directly after following a. In e-marketing we refer to this as an independent web page from the official site where we can provide immediate and intuitive information and attention, which as a result has a better user experience.",
            "THE_MAIN_OBJECTIVE_OF_A_LANDING_PAGE_IS_TO": "The main objective of a landing page is to convert the interaction with the user into an effective sale or a quick way of collecting data, thus allowing the increase of your website traffic and the visibility of your brand.",
            "WHAT_SHOULD_YOUR_LANDING_PAGE_HAVE": "What should your Landing page have?",
            "A_DEFINED_GOAL": "A defined goal",
            "A_LANDING_HAS_A_SINGLE_OBJECTIVE": "A landing has a single objective. It will always be directed to a specific audience, an intentional moment and a striking image, with the aim of converting the user into an effective sale.",
            "LAYOUT": "Layout",
            "THE_FASTEST_WAY_TO_CAPTURE_THE_ATTENTION": "The fastest way to capture the attention of users or potential customers is directly linked to the image, color and style of the design, thus creating a differentiating factor that will refer to the intention of the company to show a value proposition. and consistency for the user.",
            "CALL_TO_ACTION": "Call-To-Action",
            "IT_REFERS_TO_THE_INTENTION_OF_ACTION_OF": "It refers to the intention of action of the user with the brand, we achieve this by creating a clear invitation to interaction, using valuable content that is of interest to the user as a medium.",
            "INCREASE_YOUR_LEADS_WITH": "Increase your leads with",
            "A_LANDING_PAGE": "a Landing page",
            "THE_MAIN_OBJECTIVE_OF_A_LANDING": "The main objective of a landing is to give the user a coherent information and image to capture their immediate attention. These, being fast and forceful sources of information, will give you better results at the time of closing a purchase, without diluting expectation as normally happens on the website, since the extensive content of information can distract the user from their main objective and intention. shopping.",
            "LANDING_PAGE_MADE_BY_US": "Landing page made by us.",
            "THE_NUMBERS_DONOT_LIE": "The numbers don’t lie!",
            "WE_WORK_OUR_CLIENTD_TO_DEVELOP_DIGITAL": "We work with our clients to develop digital products and offer them the answers they need according to their needs.",
            "FORM_RESEARCH_STATEGIES_TO_IMPROVE_THE": "From research strategies to improve the customer experience with digital technologies aimed at promoting competitive",
            "ADVANTAGES_THAT_GENERATE_VALUE": "advantages that generate value for the customer.",
            "WEBSITES_WITH_30_OR_MORE_LANDING": "Websites with 30 or more Landing pages get 70% more leads and results than those with only 10.",
            "OF_MARKETING_STRATEGISTE_SEND": "Of marketing strategists send their campaign links to Landing pages thus guaranteeing greater chances of obtaining good results in conversion.",
            "COMPANIES_THAT_INCREASE_THEIR_LANDING_PAGES": "Companies that increase their Landing pages from 10 to 15 increase their ability to generate leads by 55%.",
            "QUOTE_YOUR_LANDING_PAGE": "Quote your Landing page!",
            "IF_YOUR_GOAL_IS_TO": "If your goal is to increase reach and accelerate sales in your company, do not hesitate to implement a Landing page.",
            "AT_DESTINY_WE_WILL_GIVE": "At Destiny we will give you the best advice and we guarantee the best results.",

            "WHAT_IS_AN_E_COMMERCE": "What is an E-commerce?",
            "E_COMMERCE_IS_AN_ECONOMIC_ACTIVITY": "E-commerce is an economic activity that allows the trade of different products and services from digital media, such as web pages, mobile applications and social networks. Through the internet, customers can access various catalogs of brands, services and products, at any time and in any place.",
            "AT_DESTINY_WE_TAKE_CARE_OF": "At destiny we take care of evaluating your business thus giving an articulation and approach to its expectations, solving all the challenges and goals of the client through extensive advice from our team specialized in E-commerce, resulting in clear strategies, actionable and effective that will improve the brand, the experience and the sales of your company.",
            "WHY_ARE_WE_AN_IDEAL": "Why are we an ideal",
            "PARTNER_FOR_E_COMMERCE": "partner for E-commerce",
            "CONSULTING_SERVICES": "consulting services?",
            "WE_HAVE_7_YEARS_OF_EXPERIENCE": "We have 7 years of experience and certificates that position us as a company with excellent results.",
            "WE_HAVE_THE_PRESENCE_OF_CONSULTING_": "We have the presence of consultants and developers specialized in the area of electronic commerce who will provide the best advice and support to our clients.",
            "WE_ARE_SPECIALIZED_IN_THE_MOST": "We are specialized in the most used platforms in the market, among them, WooCommerce, PrestaShop, Magento, Shopify and VTEX.",
            "OUR_SERVICES": "Our services",
            "DESING_AND_PLANING_SUPPORT": "Design and planning support.",
            "WE_ANALYZE_THE_TARGET": "We analyze the target audience.",
            "WE_IDENTIFY_AND_DEFINE_THE_PRICIING": "We identify and define the pricing and inventory strategy.",
            "WE_PLAN_AND_DESIGN_AN_E_COMMERCE": "We plan and design an e-commerce and IT roadmap.",
            "WE_DEFINE_AND_DESIGN_THE_USER_JOURNEY": "We define and design the user journey.",
            "WE_IDENTIFY_SYSTEM_INTEGRATIONS": "We identify system integrations.",
            "WE_DESIGN_THE_CUSTOMER_EXPERIENCE": "We design the customer experience management strategy.",
            "WHAT_DO_YOU_NEED_TO_KNOW_TO_MAKE_A_GOOD_E_COMMERCE": "What do you need to know to make a good E-commerce?",
            "WHAT_DO_YOU_NEED_TO": "What do you need to",
            "KNOW_TO_MAKE_A_GOOD": "know to make a good",
            "E_COMMERCE": "E-commerce?",
            "IDENTIFY_THE_IDEAL_TECHNOLOGICAL_SOLUTION_FOR_YOUR": "Identify the ideal technological solution for your e-commerce goals.",
            "E_COMMERCE_PLATFORM": "E-commerce platform recommendation.",
            "CHOOSE_THE_MOST_OPTIONAL_AND_COST": "Choose the most optimal and cost-effective cloud server solution.",
            "IDENTIFY_CHANNELS_FOR_CUSTOMER": "Identify channels for customer relationship management.",
            "USABILITY_AND_PERFORMANCE_TESTS_OF": "Usability and performance tests of the existing E-commerce.",
            "THE_SECRET_OF_A": "The secret of a",
            "SUCCESFUL_E_COMMERCE": "successful E-commerce",
            "ONLINE_SALES_IN_RECENT_MONTHS_HAVE_GROWN_BU_300": "Online sales in recent months have grown by 300% in Latin America. The global situation as a result of COVID-19 has resulted in an exponential increase in users who make purchases online, for which all companies in the world looking for a way to reposition themselves and adapt to change, have made the decision to implement electronic commerce as a strategy, thus increasing sales and improving the experience with customers through its digital channels.",
            "NO_MODE_INFORMALITY": "No more informality!",
            "SECURITY_AND_EASE_ARE_TWO_KEY_ELEMENTS_THAT": "Security and ease are two key elements that companies must transmit to users, which is why e-commerce must have the necessary security measures to build a good relationship and experience with their customers.",
            "THE_IMPORTANCE_OF": "The importance of",
            "GOOD_REFERANCE": "good references",
            "TO_ACHIEVE_A_GREATER_RAPPROCHEMENT": "To achieve a greater rapprochement with the user, the good practices of large companies such as Amazon, Alibaba or Mercado Libre should be followed and known in terms of their purchase flow.",
            "THE_UNITY_BETWEEN_PHYSICAL": "The unity between physical",
            "AND_DIGITAL_SPACES": "and digital spaces",
            "RELATIONSHIP_CHANNERS_MUST_HAVE_A": "Relationship channels must have a connection and work together. In this case, omnichannel plays an important role in achieving fluid and unified communication between the user and the company.",
            "MAKE_BUSINESS_DECISIONS": "Make business decisions looking for a differentiator!",
            "LOOKING_FOR_A_DEFFERANTIATOR": "looking for a differentiator!",
            "AT_DESTIUNY_WE_WILL_HELP_YOU_UNLOCK": "At Destiny we will help you unlock new opportunities to develop a solid customer experience strategy, taking advantage of each of the useful digital tools to generate efficient business strategies.",
            "E_COMMERCE_MADE_BYUS": " E-commerce made by us.",
            "MOVE_WITH_THE_WORD_AND_ADAPT": "Move with the world and adapt to changes",
            "AT_DESTINY_WE_WILL_HELP_YOU": "At Destiny we will help you grow without your clients leaving home.",

            "WHAT_IS_A_WEBSITE": "What is a Website?",
            "WEB_PAGES_TODAY_ARE_ONE_OF_THE_MOST": "Web pages today are one of the most effective ways to present a company to the client, showing not only the essence but also the purpose of it, also giving a better first point of contact with the user through the internet.",
            "AT_DESTINY_WE_HAVE_A_TEAM_OF_EXPERTS_IN_WEB": "At Destiny we have a team of experts in web design that will capture the essence and image of your brand in the best way, always having the functionality of the site as its objective, presenting a pleasant and easy-to-navigate site to the user.",
            "OUR_HIGHLY_TRAINED_STAFF_WILL_BE_IN_CHARGE_OF": "Our highly trained staff will be in charge of the content, design and programming, thus achieving the unification and functionality of the page. We also have a specialized support team so that your website always works efficiently and constantly.",
            "WHAT_SHOULD_YOUR_WEBSITE_HAVE": "What should your website have?",
            "IT_MUST_BE_RESPONSIVE": "It must be responsive",
            "THE_WORLD_IS_GOING_FASTER_AND_FASTER_USERS": "The world is going faster and faster and users are in constant movement, so devices such as smartphones, tablets and others have taken an important position as sources of effective and fast information, representing today a large part of internet traffic, which is why The Website must move like the user. A responsive page ensures that the message reaches users in the right way, no matter where they visit them from.",
            "WE_ASSURE_OUR_CLIENTD_SITES_THAT_ARE_FULLY_": "We assure our clients sites that are fully adaptable to each device, thus giving them a better range and versatility when having that first contact with their clients.",
            "UI": "UI",
            "IT_ALLOWS_GUIDING_USERS_THROUGH_NAVIGATION": "It allows guiding users through navigation and leading them to take action in a fast and intuitive way.",
            "UX": "UX",
            "THE_USERS_EXPERIENCE_WILL_ALWAYS_BE_THE_BEST": "The user experience will always be the best reference to improve the usability of the website, always based on good practices and regulations that will allow us to always offer a good experience.",
            "CMS_PLATFORMS": "CMS platforms",
            "A_CMS_IS_A_SYSTEM_THAT_ALLOWS_US_TO_CREATE_A": "A CMS is a system that allows us to create a website and manage it in a simple way, through an administration interface. With this interface you will be able to insert or modify texts, images and other aspects of the website, giving you the ability to do so by means of predetermined templates. In this way you can make and edit your own website without having knowledge in web development and design. It is important to bear in mind that all sites are not the same, they will all have a different objective, so you must be very clear about the objective of the website and together with us choose the most appropriate solution for your needs.",
            "E_COMMERCE_MADE_BY_US": "E-commerce made by us.",
            "SHOULD_YOU_INVERT_IN_QUALITY_WEB_DESIGN": "Should you invest in quality web design?",
            "THE_NUMBERS_DONT_LIE": "The numbers don’t lie!",
            "OF_USERS_DO_NOT_INTERACT_WITH_A_WEBSITE": "Of users do not interact with a website if the design is not attractive.",
            "OF_USERS_BROWER_THE_PRODUCES_SERVICES_PAGE": "Of users browse the products / services page before any other on a website.",
            "OF_USERS_BROWER_USEING_THEIR_SMART_PHONE": " Of users browse a website using their smartphone (mobile phone).",
            "ARE_YOU_INTERESTED_IN_CREATING_YOUR_OWN": "Are you interested in creating your own Website?",
            "DESTINY_OFFERS_YOU_THE_BEST_ADVICE_AND_SERVICE": "Destiny offers you the best advice and service to make your dream",
            "OF_HAVING_YOUR_OWN_WEBSITE_COME_TRUE": "of having your own Website come true.",

            "WHAT_ARE_PUSH_NOTIFICATIONS": "What are push notifications?",
            "THEY_ARE_THOSE_NOTIFICATIONS_THAT_APPEAR_ON": "They are those notifications that appear on users’ devices when they visit a website, or suddenly on their mobile phones.",
            "WHAT_ARE_THEY_FOR": "What are they for?",
            "PUSH_NOTIFICATIONS_ARE_USED_TO_PROMOTA": "Push notifications are used to promote a product and / or service.",
            "THEY_INVITE_YOU_DIRECTLY_TO_THE": "They invite you directly to the subscription.",
            "THEY_SERVE_TO_REPORT_AN": "They serve to report an important event or urgent message, for",
            "EXAMPLE_THE_USE_OF": "example, the use of your cards and bank accounts.",
            "THE_4_PUSH": "The 4 push",
            "USER_MESSAGES": "User messages:",
            "LET_SOMEONE_KNOW_WHEN": "Let someone know when their username is mentioned in a thread or if someone sends them a direct message through your app.",
            "TRANSACTIONAL_NOTIFICATION": "Transactional notification:",
            "BUILD_USER_LOYALTY_BY": "Build user loyalty by setting up automatic notifications triggered by events. For example, the bills that are about to be due, the delivery of their packages, changes in their flight itineraries, among other messages that can be useful and of great help for your users.",
            "CONVERSATION_CONTRILLER": "Conversation controller:",
            "SOMETIMES_WHILE_THE_USER": "Sometimes while the user interacts with your platforms there are external factors that can interrupt the effective sale or correct data collection, that is why the conversation controller will take care of sending a friendly message to remind and inform the user that their task has not been completed. , and thus be able to recover their interaction.",
            "PROMOTIONS_AND_UPDATES": "Promotions and updates:",
            "THESE_TYPES_OF_NOTIFICATIONS": "These types of notifications work to inform users of new features and opportunities, for example, a vacation discount or an exclusive trial.",
            "ADVANTAGES_OD_PUSH_NOTIFICATION": "Advantages of Push notification",
            "THEY_SERVE_TO_COMMUNICATE": "They serve to communicate important news for the user and the company, for example, about new products, promotions, launches and events.",
            "BEING_NOTIFICATIONS": "Being notifications, they generate a feeling of urgency or alert in users.",
            "SECRETS_TO_CREATING_A_GOOD": "Secrets to creating a good Push strategy",
            "BY_FOLLOWING_THESE_TIPS": "By following these tips and giving a message thought about the needs of your company, you will be able to guarantee satisfactory results and good communication bridges with the current user and future prospects.",
            "CREATE_LONKS_AND_THINK": "Create links and think the texts seeking to retain the reader.",
            "USE_LOTS_OF_COOL_ACTION": "Use lots of cool action verbs and Calls to Action.",
            "USE_DATA_AND_STATISTICS": "Use data and statistics (Proven!).",
            "CUSTOMIZE_YOUR_MESSAGE": "Customize your messages to get the reader’s attention.",
            "DO_NOT_TRY_TO_COMMUNICATE": "Don’t try to communicate everything at once.",
            "CONSIDER_IT_AS_ONE_MORE": "Consider it as one more tool within a comprehensive omnichannel plan.",
            "LET_YOURSELT_BE_SURPRISED": "Let yourself be surprised and surprise your",
            "USERS_WITH_THE_PUSH": "users with the push",
            "IN_DESTINY_WE_WILL_GIVE": "In Destiny we will give you the best results!.",

            "THIS_IS_HOW_WORK": "This is how we work!",
            "1_COMPUTER_CONSULTING": "1. Computer consulting",
            "WE_WILL_START_WITH_PLATFORM": "We will start with platform and solution consulting to help our clients gain a clear view of the IT solution they want to implement. We also provide strategic consulting, advising companies on how to improve their IT strategies.",
            "2_IMPLEMENTATION": "2. Implementation",
            "WE_WILL_DEVELOP_AND_TEST": "We will develop and test an IT solution to assist our clients in integration, data migration, and other interrelated processes, thereby ensuring that the new solution provided is integrated into corporate workflows and contributes to business value.",
            "3_SUPPORT": "3. Support",
            "WE_PROVIDE_COMPREHENSIVE_SUPPORT": "We provide comprehensive support for the IT solutions used by our clients, no matter if they are already existing or created by our own team, adding new functions, correcting errors, guaranteeing better interoperability, performance and usability of the same.",
            "CHOOSE_THE_OPTION": "Choose the best option focused",
            "ON_YOUR_NEEDS": "on your needs.",
            "AT_DESTINY_WE_WILL_PROVIDE_YOU_WITH": "At Destiny we will provide you with the best advice from the first moment",
            "OF_CONTACT_THUS_GIVING_YOU_A_GUIDE": "of contact, thus giving you a guide and a wide portfolio of options.",
            "WE_OFFER_YOU": " We offer you",
            "PROJECT_SUPPORT_SERVICES": "Project support services.",
            "IMFORMATION_ARCHITECT_AND_MORE": "Information Architect and more.",
            "TECHONOLOGY_EXPERTS": "Technology experts",
            "OUR_TEAM_HANDLES_a_WIDE": "Our team handles a wide variety of languages and resources, which allow us to always provide the best solutions to our clients, anywhere in the world.",
            "PARTNERS": "Partners",
            "DESTINY_HAS_AS_ALLIES_GREAT": "Destiny has as allies great technological platforms that allow us to automate, manage, send and create efficient communications for you and your clients.",
            "CUSTOMERS": "Customers",
            "BY_CREATING_SOLUTIONS_AND_GENERATING": "By creating solutions and generating a differentiating factor in our clients’ products, we have created an important portfolio of work, thus forming solid relationships not only between Destiny and its clients but also between companies and their users.",

            "WHAT_ARE_CONSULTING_SERVICES": "What are consulting services?",
            "CONSULTING_SERVICES_ALLOW_COMPANIES_TO": "Consulting services allow companies to implement information technology strategies and solutions to achieve alignment between the business and its platforms, thus driving the maximum value of current initiatives.",
            "TRUST_US": "Trust us!",
            "WE_HAVE_7_YEARS_OF": "We have 7 years of experience in each of these areas.",
            "SOFTWARE": "Software development",
            "DEVELOPMENT": "development.",
            "TECHNICAL_AND": "Technical and",
            "APPLICATION_SUPPORT": "application support.",
            "INFRASTRUCTURE_MANAGEMENT": "Gestión de infraestructura.",
            "MANAGEMENT": "management.",
            "CONSULTING_AND": "Consulting and",
            "MIGRATION_IN_THE": "migration in the",
            "CLOUD": "cloud.",
            "OUR_TEAM": "Our team!",
            "WE_HAVE_A_GROUP_OF_SOFTWARE": "We have a group of software developers covering a variety of technology stacks.",
            "EXPERIENCE_AND_INTERFACE": "Experience and interface designers.",
            "SOLUTION_ARCHITECTS": "Solution architects.",
            "PROFESSIONALS_IN_QUALITY": "Professionals in quality control and other roles.",
            "WE_ARE_EXPERTS": "We are experts!",
            "AT_DESTINY_WE_ARE_CERTIFIED": "At Destiny we are certified professionals in:",
            "PLATFORM_BASED_AND_CUSTOM": "Platform-based and custom software development.",
            "WEB_DEVELOPMENT": "Web development",
            "ANALISIS_OF_DATA": "Analysis of data",
            "CYBERSECURITY_AND_MORE": "Cybersecurity and more.",
            "INDUSTRIES_IN_WHICH_WE": "Industries in which we venture",
            "BANKS": "Banks.",
            "FOOD_CAMPANIES": " Food companies.",
            "CLOTHING_BRANDS": "Clothing brands.",
            "INFORMATIVE": "Informative.",
            "GOVERNMENT_ENTITIES_AND_MORE": "Government entities and more.",
            "WE_ACHIEVE_THE_OBJECTIVES": "We achieve the objectives of our clients",
            "STOP_THE_MACHINES": "Stop the machines!",
            "WE_FOCUS_ALL_OUR": "We focus all our attention on the advisory stage to overcome all possible IT challenges, and thus establish a transformation strategy in the company.",
            "WE_OFFER_COMLATE_SERVICE": "We offer complete service packages in IT project assistance. In which we plan, design, implement and continuously support technology-based solutions.",
            "KPI_PERFOMANCE_METERS": "KPI Performance Meters",
            "WE_OFFER_35_REDUCTION_IN": "We offer 35% reduction in IT operating costs.",
            "WE_HAVE_A_99_AVAILABILITY_OF": "We have a 99% availability of the service.",
            "WE_GUARANTEE_A_50": "We guarantee a 50% reduction in the average time to provide solutions.",
            "WE_PROVIDE_96_IN": "We provide 96% in CSAT.",
            "AT_DESTINY_WE_HAVE_THE_TRUST": "At Destiny we have the trust of clients such as",
            "WE_WANT_TO_WORK_WITH_YOU": "We want to work with you and give",
            "YOU_THE_BEST_ADVICE": "you the best advice",
            "LETS_CREATE_TOGETHER": "Let’s create together, let’s achieve your current goals,",
            "AND_WHY_NOT": "and why not? We create many new ones",

            "FOOTER_HOME": "Home",
            "FOOTER_ABOUT": "About",
            "FOOTER_WORK": "Work",
            "FOOTER_SERVICE": "Services",
            "FOOTER_PEOPLE": "People",
            "FOOTER_FREELANCER": "Freelancer",
            "FOOTER_CONTACT": "Contact",

            "BANNER_ABOUT": "Sobre Destiny",
            "BANNER_PEOPLE": "Equipo",

            "OUR_WORK": "Our work",

            "PRO_QUE_TRABAJAR_CON": "Why work with us?",
            "WE_ARE_THE_PERFECT_COMPLEMENT_BETWEEN": "We are the perfect complement between youth and experience. We currently have more than",
            "IN_THE_MARKET_THUS_GUARANTEEING_THE_CORRECT_OPERATION": "In the market, thus guaranteeing the correct operation and the acquisition of good results in each of our projects.",

            "WE_ARE_A_COMPANY_IN_CONSTANT_EVOLUTION": "We are a company in constant evolution!",
            "WE_ARE_NOT_SATISFIED_WITH_THE_BASIC_OPRATION": "We are not satisfied with the basic operation of our projects, we are always looking to revolutionize and innovate with new strategies that make a difference.",
            "SECURITY_EXPERTS": "Security experts!",
            "WE_WORK_REMOTALY_WITH_OUR_TEAMS_TO_PROVIDE": "We work remotely with our teams to provide peace of mind to our customers, always guaranteeing the stability and security of each of their products.",
            "DESTINY_NEVER_SLEEPS": "Destiny never sleeps!",
            "WE_WORK_24_HOURS_A_DAY_FOR_OUR_CUSTOMERS": "We work 24 hours a day for our customers, providing constant support and immediate solutions. No matter where you are, you will always find support from our professionals.",

            "CORPORATE_VALUES": "Corporate values",
            "WE_BELIEVE_IN_CREATIVE_MIND_IN_THE_MAGIC_OF": "We believe in creative minds, in the magic of including new cultures and personalities in our team, seeing our young people innovate every day and valuing the experience of those who have been building trust in the company for some time, we are motivated to learn every day from our colleagues and their different perspectives, always reaching the same corporate goal, integrity always goes above any expectation.",
            "CREATIVITY": "Creativity",
            "INCUSION": "Inclusion",
            "INNOCATION": "Innovation",
            "TRUST": "Trust",
            "FELLOWSHIP": "fellowship",
            "INTEGRITY": "Integrity",

            "WORK_WITH_US": "Work with us",
            "DESTINY_WANTS_IN_ITS_TEAM_PEOPLE_WHO_ARE_AN": "Destiny wants in its team people who are an important part of the company, because we are a diverse and versatile place, a young world full of experience, in which all skills, knowledge and experience are invaluable. We have been growing and learning for 7 years and today we are not going to stop. Our destiny and our sense is to fulfill dreams and objectives, so never hesitate to join a team that every day will work to fulfill your professional goals and dreams. We are a team full of attitude and motivation, and we look forward to working with people like you who do not find limits in their way.",
            "WE_HAVE_3_OFFICES_NO_MATTER_WHERE_YOU_ARE": "We have 3 offices, no matter where you are and what your expertise is; Destiny celebrates all the goals obtained, because the inspiration and motivation of our team makes us achieve all our objectives. Take the risk to motivate and be valued in your work environment.",

            "PEOPLE_FORM": "Form",
            "PEOPLE_NAME": "Names",
            "PEOPLE_LAST_NAME": "Last name",
            "PEOPLE_EMAIL_ADDRESS": "E-mail address",
            "PEOPLE_CONTACT_NAMUBER": "Contact Number",
            "PEOPLE_YOU_WANT_TI_ADD_A_COMMENT": "You want to add a comment...",
            "PEOPLE_CURRICULUM": "Currículum",
            "PEOPLE_DROP_THE_FILE_TO_LOAD": "Drop the file to load",
            "PEOPLE_JOIN_NOW": "Join now",

            "QUOTE_HERE": "Quote here",

            "REQUEST_FOR_QUOTATION": "Request for Quotation",
            "QUOTATION_FULL_NAME": "Full Names*",
            "QUOTATION_EMAIL_ADDRESS": "E-mail address*",
            "QUOTATION_CONTACT_NUMBER": "Contact Number*",
            "QUOTATION_SELECT_A_SERVICE": "Select a service*",
            "QUOTATION_BUGGEET_PROJECT": "Select the budget for your project*",
            "QUOTATION_DATE_QUOTATION": "Select the date of your quotation*",
            "QUOTATION_DETAILS": "Write more details about your project*",
            "QUOTATION_SEND_REQUEST": "Send request",

            "QUOTATION_FULL_NAME_REQUIRED": "Full name is required",
            "QUOTATION_EMAIL_ADDRESS_REQUIRED": "Email address is required",
            "QUOTATION_CONTACT_NUMBER_REQUIRED": "Contact is required",
            "QUOTATION_SELECT_A_SERVICE_REQUIRED": "Please select service",
            "QUOTATION_BUGGEET_PROJECT_REQUIRED": "Please select project budget",
            "QUOTATION_DATE_QUOTATION_REQUIRED": "Date  is required",

            "PEOPLE_FIRST_NAME_REQUIRED": "First name is required",
            "PEOPLE_LAST_REQUIRED": "Last name is required",
            "PEOPLE_EMAIL_REQUIRED": "Email is required",
            "PEOPLE_CONTACT_REQUIRED": "Contact is required",
            "PEOPLE_MESSAGE_REQUIRED": "Discription is required",
            "PEOPLE_UPLOAD_DOCUMENT_REQUIRED": "Escribe más detalles de tu proyecto",

            "BET_ON_A_FAST_AND_EFFICIENT_COMMUNICATION": "Bet on a fast and efficient communication with the user, so that you always get the best results in the shortest estimated time.",
            "SCHEDULE_A_FREE_CONSULTATION": "Schedule a free consultation",
            "HERE": "Here",
            "DIFFERENTIATING_FACTOR": "Differentiating Factor",
            "WE_HAVE_SUCCESEFULLY_RECEIVED_YOUR": "We have successfully received your resume. The person in charge of the area will contact you."
        },


        es: {
            "HOME": "Home",
            "WORK": "Work",
            "ABOUT": "About",
            "PEOPLE": "People",
            "SERVICES": "Services",
            "WHAT_WE_DO": "What We Do",
            "KNOW_OUR_SOLUTIONS": "Conozca nuestras soluciones _",
            "OUR_SERVICES": "Nuestros servicios",
            "WE_WORK_WITH_OUR_CLIENTS_TO_DEVELOP_DIGITAL": "Trabajamos con nuestros clientes para desarrollar productos digitales y ofrecerles las respuestas que necesitan según sus necesidades. Desde la investigación de estrategias para mejorar la experiencia del cliente con tecnologías digitales orientadas a promover ventajas competitivas que generen valor para el cliente.",
            "EMAILS": "Correos electrónicos",
            "BANNER": "Banner",
            "APP": "App",
            "LANDING_PAGE": "Landing Page",
            "WEBSITE": "Website",
            "E-COMMERCE": "E-Commerce",
            "SMS": "SMS",
            "PUSH_NOTIFICATION": "Push Notification",
            "CONSULTING": "Consultoría",
            "SUPPORT": "Soporte",
            "PIECE_OF_DIGITAL_ADVERTISING_THAT_MIXES_IMAGES": "Pieza de publicidad digital que mezcla imágenes, texto y, a veces, contenidos interactivos como el audio y la animación.",
            "HERRAMIENTAS_DE_SOFTWARE_ESCRITAS_EN_DIFERENCES": "Herramientas de software escritas en diferentes lenguajes de programación, caracterizadas por ser útiles, dinámicas, fáciles de instalar y manejar.",
            "ES_LA_ESENCIA_DE_LA_ESTRATEGIA_PARA_GENERAR_ENA": "Es la esencia de la estrategia para generar una mejor interacción, proporcionando información importante para el usuario.",
            "THEY_ARE_ONE_OF_THE_MOST_EFFECTIVE_WAYS_TO": "Es una de las formas más efectivas de presentar una empresa al cliente, mostrando no solo la esencia sino también el propósito de la misma.",
            "SON_UNA_DE_LAS_FORMAS_MAS_EFICACES_DE_PRESENTAR": "Son una de las formas más eficaces de presentar una empresa al cliente, mostrando no sólo la esencia sino también el propósito de la misma.",
            "EL_USO_ESTE_SERVICIO_DARA_LUGAR_A_UN": "El uso de este servicio dará lugar a un contacto ágil y rápido con sus clientes actuales o potenciales.",
            "SON_ESAS_NOTIFICACIONS_QUE_APARENCEN_EN_LOS_DISPOSITIVE": "Son esas notificaciones que aparecen en los dispositivos de los usuarios cuando visitan un sitio web, o de repente en sus teléfonos móviles",
            "DEFINA_EL_ALCANCE_FUNCIONAL_DE_SU": "Defina el alcance funcional de su proyecto para planificar el presupuesto y el calendario del mismo.",
            "ENTENDEMOS_QUE_UNA_TALLA_NO_SIRVE_PARA": "Entendemos que una talla no sirve para todos. Podemos trabajar con usted para definir y desarrollar una solución personalizada que satisfaga sus necesidades.",

            "LEARN_MORE": "Conoce más",
            "SEE_PROJECTS": "Ver proyectos",
            "CONTACT_US_": "Contactanos_",
            "WE_WANT_TO_WORK_WITH_YOU_AND_PROVIDE_YOU_WITH": "Queremos trabajar con usted y proporcionarle el mejor asesoramiento para que juntos podamos alcanzar sus objetivos actuales y, ¿por qué no? Creamos muchos nuevos.",
            "MAKE_SOMETHING_YOU_LOVE": "Hacer algo que te guste __",
            "ABOUT_US": "Sobre nosotros",
            "WE_ARE_A_COMPANY_DEDICATED_TO_DIGITAL_PRODUCTION": "Una empresa dedicada a la producción y desarrollo digital, nos enfocamos en la creación de soluciones que permitan a nuestros clientes generar un valor diferencial en sus productos y la manera de mostrarlos al público, construyendo así relaciones sólidas y duraderas con sus usuarios actuales y facilitando la adquisición de nuevos clientes.",
            "PRESENTS_A_SOLUTION_THAT_SAVES_TIME_AND_EFFORT": "Presenta una solución que ahorra tiempo y esfuerzo en la producción de piezas y proyectos digitales.",
            "OUR_GROUP_OF_EXPERTS_WORK_ON_THE_DEVELOPMENT": "Nos caracterizamos por tener un proceso integral, donde la experticia de nuestros profesionales y el constante contacto con nuevas tecnologías, dan como resultado un trabajo sólido no solo en estrategia sino también en resultados. Lo más importante para Destiny es la calidad del servicio y las experiencias excepcionales que brindamos a nuestros clientes.",

            "7_YEARS": "+7 años de ",
            "EXPERIENCE": "experiencia_",
            "OPERATION_24_7": "¡Estamos en 3 países!",
            "DEDICATED_TO_FINE_AND_DEVELOP_THE_BEST": "Sabíamos que nuestro Destino no estaba en un solo lugar, así que decidimos movernos Y seguir nuestra constante evolución no solo en un país, sino en 3 bases estratégicas que hoy en día nos facilitan una comunicación más directa y efectiva con todos nuestros clientes en el mundo",
            "MIAMI": "Miami",
            "USA": "USA",
            "BOGOTA-MEDELLIN": "Bogotá - Medellín",
            "COLOMBIA": "Colombia",
            "AHMEDABAD": "Ahmedabad",
            "INDIA": "India",
            "BEHIND_THE_SCENES": "Detrás de cámara __",
            "WE_COME_FROM_ALL_OVER_THE_WORD": "Conoce nuestro equipo",
            "HAVE_A_DIVERSITY_OF_EXPERIENCE_AND": "tienen una diversidad de experiencias y formaciones, y practican una variedad de disciplinas. Pero compartimos una curiosidad perpetua y la creencia de que las cosas que hacemos deben contribuir a crear un futuro más humano.",
            "PROJECT_MANAGER": "Project Manager",

            "RESPONIBLE_FOR_RECEIVING": "Es el encargado de recibir y entender el propósito de cada una de las tareas y requerimientos entregados por el cliente. Nuestro Project Manager se encarga de organizar todo el cronograma de tareas con sus respectivos plazos e información detallada de los entregables, manteniendo así un proceso integral y organizado en cada uno de los proyectos siendo siempre consciente de la capacidad de la compañía y la importancia del cumplimiento en los tiempos de entrega. Además, se encargará de velar por la ejecución de buenas prácticas, roles de soporte y mantenimiento, que quedarán por escrito en el documento principal de cada cliente.",
            "FULLSTACK": "FullStack",
            "IT_WILL_BE_IN_CHARGE_OF": "Nuestros desarrolladores FullStack son las personas encargadas del desarrollo de software tanto de cliente como de servidor. Expertos en HTML y CSS. Además se encargan de programar los navegadores tales como, (JavaScript, jQuery, Angular o Vue) y en el caso de los servidores implementando el buen uso de (PHP, NodeJs) para su programación.",
            "FRONTND": "FrontEnd",
            "RESPONSIBLE_FOR_TRANSLATING_THE": "Es la persona encargada de traducir los lenguajes de diseño y estilo visual realizadas y aprobadas en etapas previas a códigos HTML semántico y CSS.",
            "QA": "QA",
            "IT_CONFIGURES_THE_OPERATION": "Nuestros expertos en calidad se encargarán de configurar el funcionamiento del sistema implementando las mejores prácticas en código, junto con la creación de la documentación de cada uno de los módulos y servicios implementados para llevar con éxito cada uno de los proyectos. Además velará por el correcto funcionamiento y rendimiento de la solución integrada para cada cliente.",
            "UI": "UI",
            "THE__UI_DESIGNER": "Nuestros UI Designer se encargan de crear visualmente la interfaz del producto para que sea lo más acorde con la experiencia que el cliente busca implementar para el usuario. Además, se encargará de la creación de elementos interactivos para que la interacción del cliente con el producto sea mucho más dinámica y atractiva, haciendo de cada uno de los productos un proyecto responsive apto para cualquier plataforma como (móvil, tableta y web).",
            "UX": "UX",
            "A_USER_EXPERIENCE": "Nuestro diseñador de experiencia de usuario (UX Designer) es un profesional que se enfoca en cada de uno de los detalles para que la percepción y sensación del usuario al utilizar cualquiera de los productos sea intuitiva y cómoda, creando así en la mente de las personas un desempeño óptimo y un funcionamiento amable.",
            "COPY": "Copy",
            "PERSON_WHO_IS_IN_CHARGE_OF": "Es la persona encargada de pensar en la estrategia creativa y el lenguaje de cada campaña, creando así narrativas y textos llamativos que mejoraran la experiencia y comunicación en cada una de las estrategias a implementar.",
            "BACKEND": "Backend",
            "THE_BACKEND_IS_THE_PART_OR_BRANCH": "El Back-End es la rama del desarrollo web que se encargará de que la página funcione creando una lógica en su programación, generando el conjunto de acciones que suceden dentro de una web. Son lógicas invisibles que son la base de un correcto funcionamiento.",
            "DBA": "DBA",
            "THE_DATABASE_ADMINISTRATOR": "Es el administrador de bases de datos (DBA). Se encarga de administrar las tecnologías de la información y la comunicación, convirtiéndose en el responsable de los aspectos técnicos, tecnológicos, científicos, inteligencia de negocios y legales en las bases de datos.",
            "INFRASTRUCTURE": "Infraestructura",
            "ENGINEER_IN_THE_AREA_OF_INFORMATION": "El Analista de Datos o Data Analyst es un perfil que gracias al análisis y la interpretación de los datos, puede establecer estrategias y formatos de visualización en métricas para el entendimiento de los modelos de negocio. Por lo tanto, debe saber recopilar datos, interpretarlos y a la vez analizarlos de forma estadística.",
            "ARCHITECT_OF_SOLUTIONS": "Arquitecto de soluciones",
            "THIS_PROFILE_IS_IN_CHARGE_OF_DESIGNING": "Es el encargado de diseñar, diagramar y tomar decisiones de alto nivel durante la ejecución del proyecto.",
            "DATA_ENGINEER": "Ingeniero de datos",
            "WEB_MASTER": "Web Master",
            "ES_EL_ENCARGADO_DE_EVALUAR_Y_GES": "Es el encargado de evaluar y gestionar las actualizaciones de cada producto como páginas web, Google Analytics y códigos necesarios para medir el desempeño del Sitio o Apps.",
            "OUR_LEADERSHIP": "Nuestros Leadership",
            "JUSN_EDUARDO_DELGADO": "Juan Eduardo Delgado",
            "PRODUCT_DIRECTOR": "Product Director",
            "JUAN_JOSE_ESCOBAR": "Juan José Escobar",
            "DEVELOPMENT_DIRECTOR": "Development Director",
            "SANDRA_CAMELO": "Sandra Camelo",
            "FINANCIAL_AND_ADMINISTATIVE_DIRECTOR": "Financial and Administrative Director",
            "LINA_CABRERA": "Lina Cabrera",
            "DESIGNEER_DIRECTOR": "Designer Director",
            "ANA_MARIA_ARANGO": "Ana María Arango",
            "COMMERCIAL_DIRECTOR": "Commercial Director",
            "FEDERICO_RAMIREZ": "Federico Ramirez",
            "DIRECTOR_DE_OPERACIONES_EXTERNO": "Director de Operaciones Externo",
            "SEBASTIAN_ZUNIGA": "Sebastian Zúñiga",
            "MANAGEMENT_DIRECTOR": "Managment Director",
            "YOGESH_SHARMA": "Yogesh Sharma",
            "MANAGMENT_DIRECTOR_INDIA": "Managment Director India",
            "I_ACCEPT_THE": "acepto el",
            "TERMS_AND_CONDITIONS": "Términos y condiciones",
            "SEND_MESSAGE": "Enviar mensaje",
            "FREELANCER": "Persona de libre dedicación",
            "SOCIAL_MEDIA": "Persona de libre dedicación",
            "NAME": "Nombre",
            "EMAIL": "Email",
            "PHONE": "Teléfono",
            "CONTACT_AREA": "Area de contacto",
            "MESSAGE": "Mensaje",
            "ELASTIC_PRODUCTION": "Producción elástica",
            "AUTOMATED_DIGITAL_PRODUCTION_WITH_THAT_WILL": "Producción digital automatizada con IA que te ofrecerá las mejores soluciones tecnológicas.",
            "BUY_NOW": "Compra ahora",
            "ONLINE": "en línea",
            "QUOTATION": "Cotización",
            "PRODUCTION": "Producción",
            "FAST": "rápidos",
            "DELIVERY": "Tiempos de entrega",
            "SIMPLE_EASY_FAST": "SIMPLE, FÁCIL Y RÁPIDO",
            "MASSIVE_PRODUCTION_ASSETS": "Producción masiva de Productos Digitales",
            "WE_ARE_PROVIDING_A_DIGITAL": "Brindamos soluciones que incluyen una gran cantidad de opciones para diferentes productos.",
            "QUICK_OPTIONS": "Opciones rapidas:",
            "ALL": "Todos",
            "SPECIAL_PROJECT": "PROYECTOS ESPECIALES",
            "DIGITAL_SOLUTION": "Soluciones Digitales",
            "WE_DELIVER_WEB_AND_MOBILE_APP": "Brindamos servicios de desarrollo de aplicaciones web y móviles a empresas globales desde 2014, con un 100% de éxito en la entrega de proyectos.",
            "WEBSITE": "Website",
            "BEING_A_LEADING_WEBSITE": "Al ser una empresa líder en desarrollo de aplicaciones de sitios web, nuestros desarrolladores trabajan con tecnologías web para ofrecer soluciones web personalizadas.",
            "LANDINGS": "Landings",
            "WE_HAVE_AN_EXPERTISE_TO_DEVELOP_A": "Contamos con personas expertas que lo guiarán para generar tráfico, mejorar su conversión y construir su Landing.",
            "APPS": "Apps",
            "FROM_B2B_OR_B2E_APPS": "Desde aplicaciones B2B o B2E para grandes y pequeñas empresas, ofrecemos un servicio comprobado de desarrollo de aplicaciones móviles de alto impacto y orientados a resultados.",
            "WE_ARE_A_LEADING_ECOMMERCE_WEB": "Somos una empresa líder en desarrollo web de comercio electrónico que crea atractivas soluciones web para las mejores experiencias de compra en línea.",
            "SUPPORT": "Soporte",
            "WE_UNDERSTAND_ONE_SIZE_DOESNOT_FIT_ALL": "Entendemos que una talla no sirve para todos. Podemos trabajar con usted para definir y desarrollar una solución personalizada que satisfaga sus necesidades.",
            "CONSULTING": "Consultoría",
            "DEFINE_FUNCTIONAL_SCROPE_OF_YOUR": "Defina el alcance funcional de su proyecto para planificar el presupuesto y el cronograma del proyecto.",
            "SEARCH_ASSETS": "Buscar productos",
            "BUY_ALL": "Descubra todas las",
            "OPTIONS": "opciones",
            "BUY_BUTTON": "Comprar",
            "VIEW_DETAILS": "Ver detalles",
            "TOTAL_QUANTITY": "Cantidad total",
            "ESTIMATED_TIME_AND_COST": "Tiempo y Costo Estimados",
            "VIEW_ALL": "Ver más",
            "STARTING_FROM": "Empezando desde",

            "DETAILS": "Detalles",
            "ASSET_SUMMARY": "Resumen de activos",
            "PAYMENT": "Pago",
            "PERSONAL_DETAILS": "Personal Details",
            "NAME": "Nombre",
            "PLEASE_PROVIDE_NAME": "Por favor proporcione el nombre",
            "YOUR_FIRST_NAME": "Su nombre",
            "LAST_NAME": "Apellido",
            "PLEASE_PROVIDE_LAST_NAME": "Por favor proporcione su apellido",
            "YOUR_LAST_NAME": "Tu apellido",
            "PLEASE_PROVIDE_EMAIL": "Por favor proporcione un correo electrónico",
            "PLEASE_PROVIDE_VALID_EMAIL": "Proporcione un correo electrónico válido",
            "NAME_GEMAIL": "nombre@gmail.com",
            "COMPANY_NAME": "nombre de empresa",
            "PLEASE_PROVIDE_COMPANY_NAME": "Proporcione el nombre de la empresa",
            "YOUR_COMPANY_NAME": "El nombre de tu compañía",
            "EXPECTED_DELIVERY_DATE": "Fecha de Entrega Estimada",
            "PLEASE_PROVIDE_DATE": "Por favor proporcione la fecha",
            "ACCEPT_AND_CONTINUE": "Aceptar y continuar",

            "ASSET_SUMMARY": "Resumen de activos",
            "PRODUCT": "PRODUCTO",
            "STATUS": "ESTADO",
            "START_DATE": "FECHA DE INICIO",
            "FINAL_DATE": "FECHA FINAL",
            "PRICE": "PRECIO (en USD)",
            "PAYMENT_DETAILS": "Detalles del pago",
            "NAME_ON_CARD": "Nombre en la tarjeta",
            "PLEASE_PROVIDE_CARD_NAME": "Proporcione el nombre de la tarjeta",
            "YOUR_NAME": "TU NOMBRE",
            "CREDIT_CARD_NUMBER": "Número de Tarjeta de Crédito",
            "PLEASE_PROVIDE_YOUR_CARD_NUMBER": "Proporcione su número de tarjeta",
            "PLEASE_PROVIDE_VALID_CARD_NUMBER": "Proporcione un número de tarjeta válido",
            "YOUR_CREDIT_CARD_NUMBER": "Su número de tarjeta de crédito",
            "EXPIRATION": "Vencimiento",
            "PLEASE_PROVIDE_MM_YY": "Proporcione MM / AA",
            "PLEASE_PROVIDE_VALID_EXPIRATION": "Proporcione un vencimiento válido",
            "MM_YY": "MM / AA",
            "CVV": "CVV",
            "PLEASE_PROVIDE_CARD_CVV": "Proporcione la tarjeta CVV",
            "PLEASE_PROVIDE_VALID_CVV": "Proporcione un CVV válido",
            "YOUR_CVV": "Tu CVV",
            "THANK_YOU": "¡Gracias!",
            "OTP_VERIFICATION": "Verificación OTP",
            "YOU_ARE_ALREADY_A_REDISTERED_USER": "Ya eres un usuario registrado. Hemos enviado una OTP a su dirección de correo electrónico registrada. Ingrese el código otp de 6 dígitos para continuar.",
            "ENTER_6_DIGIT_OTP": "Ingrese OTP de 6 dígitos",
            "PLEASE_PROVIDE_YOUR_OTP_CODE": "Proporcione su código otp ...",
            "PLEASE_PROVIDE_YOUR_VALID_OTP_CODE": "Proporcione su código otp válido ...",
            "WE_HAVE_SENT_YOU_NEW_OTP": "Le hemos enviado un nuevo código otp, utilícelo ...",
            "CONTINUE": "Continuar",
            "RESEND_OTP": "Reenviar OTP",
            "WE_ARE_VALIDATING_YOUR_USER": "Estamos validando su usuario ...",
            "WE_ARE_CREATING_YOUR_PROJECT": "Estamos creando tu proyecto ...",
            "WE_ARE_CREATING_YOUR_QUOTATION": "Estamos creando su cotización ...",
            "WE_ARE_CREATING_TASKS_FOR_YOUR_QUOTATION": "Estamos creando tareas para su cotización ...",
            "ASSET_DETAILS": "Detalles del activo",
            "QTY": "CANTIDAD",
            "EFFORT_IN_HOURS": "ESFUERZO (en horas)",

            "BANNER_WORK": "Proyectos",
            "MAKE_SOMETHINNG_YOU_LOVE": "Hacemos cosas con amor __",
            "BANNERS": "Banners",
            "E_EMAILS": "Correos electrónicos",
            "E_EMAIL_SEND": "Email send",
            "E_EMAIL_HTML": "Email HTML",
            "POS_MATERIALS": "Materiales POS",
            "SOCIAL_CONTENT": "Contenido social",
            "ECOMMERCE": "ECOMMERCE",
            "AMBER_CLOTHING": "Amber Clothing",
            "CLOTHES_AND_ACCESSORIES_FOR_YOUR_BABY": "Ropa y complementos para tu bebé.",
            "PRINCE_OF_WALES": "Prince of Wales Country Club",
            "CLUB": "Club",
            "IT_IS_A_PRESTIGIOUS_CLUB_LOCATED_IN_SANTIAGO_DE": "Es un prestigioso Club ubicado en Santiago de Chile, lo definen como un lugar incomparable donde toda la familia puede disfrutar y vivir experiencias de primer nivel.",
            "VOD_BANCOLOMBIA": "VOD - Bancolombia",
            "IT_IS_A_MANAGEMENT_PLATFORM_THAT__ALLOWS_YOU_TO_UPLOAD": "Es una plataforma de gestión que te permite subir contenido audiovisual para Bancolombia.",
            "E_MAIL": "E-MAIL",
            "RAPPI": "Rappi",
            "IT_IS_A_PLATFORM_THAT_HANDLES_MASS": "Es una plataforma que maneja la comunicación masiva. Diseñan un gran flujo de correos electrónicos para sus clientes.",
            "RAPPI_AND_MORE": "Rappi y más",
            "PUT_THE_FINISHING_TOUCH_ON_YOUR_FAVORITE": "Dale el toque final a tus aperitivos favoritos o deja volar tu imaginación con el recetario de la A a la Z.",
            "LANDING": "LANDING",
            "CERVEZA": "Cerveza",
            "ANDINA": "Andina",
            "IT_IS_A_PERSTIGIOUS_CLUB_LOCATED_IN_SANTIAGGO_DE": "Evento que permite ganar premios, mediante la compra de Cervezas Andina. Canjeando sus códigos en la página de inicio del evento promocional.",

            "ELASTIC_PRODUCTION": "Producción elástica",
            "WE_HAVE_OFFICES_AND_TEAMS_THE_WORLD_SERVING": "Contamos con oficinas y equipos en todo el mundo que atienden a diferentes mercados.",
            "OUR_VISION_HAS_LED_DESTINY_TO_BECOME_A_TOP_IT": "Nuestra visión ha llevado a Destiny a convertirse en una de las principales empresas de TI para ofrecer diversas soluciones de movilidad lideradas por la industria. El objetivo es empoderar a los clientes y las empresas mediante la creación de nuevas posibilidades aprovechando las tecnologías de hoy y de mañana con la máxima calidad, satisfacción y transparencia.",
            "CLIENT_CENTRIC_APPROACH": "Enfoque centrado en el cliente",
            "FOR_ANY_BUSINESS_CUSTOMERS_ARE_ALWAYS_AT_THE_CENTER": "Para cualquier negocio, los clientes siempre están en el centro. Siendo una empresa líder en desarrollo de aplicaciones web y móviles, nuestra definición va más allá de nuestros clientes directos. Siempre partimos de donde usted está con sus ideas y pensamos desde la perspectiva de SUS clientes finales, sus áreas de dolor e ideamos una solución que resuelve los problemas centrales en beneficio de su negocio.",
            "EFFECTIVE_COLLABORATION": "Colaboración eficaz",
            "COMMUNICATION_IS_THE_KEY__WE_TENF_TO_OVER": "La comunicación es la clave. A veces tendemos a comunicarnos en exceso. Aunque suene abrumador, eventualmente funciona de maravilla para el negocio de nuestros clientes. Cuando nuestro equipo de desarrollo colabora con nuestros clientes sentados a miles de kilómetros de distancia, es evidente que nos mantenemos en contacto con plataformas y medios que permiten efectividad, transparencia y precisión.",
            "QUALITY_DELIVER_IN_TIME": "Calidad Entrega a tiempo",
            "WE_HAVE_A_GREAT_SET_OF_INTERNAL_FRAMEWORKS": "Contamos con un gran conjunto de marcos internos con la mejor infraestructura de su clase que nos permite ofrecer soluciones con calidad superior en todo momento. Todos estos años, hemos aprendido cómo prevenir fallas y replicar el éxito. No solo nos jactamos de la calidad. Lo definimos y lo entregamos a tiempo.",
            "RESULT_ORIENTED_APPROACH": "Enfoque orientado a resultados",
            "THE_ROOTS_OF_OUR_SUCCESS_ARE_CONNECTED_TO_OUR_VISION": "Las raíces de nuestro éxito están conectadas con nuestra visión y ejecución del mismo. Desde el reclutamiento hasta los entregables de calidad, mantenemos intactos nuestros valores. Contratamos seres humanos apasionados que tienen ese fuego ardiendo en ellos. Personas que realmente quieran trabajar con nosotros y comprendan la importancia de las expectativas del cliente para ofrecer resultados de calidad.",

            "INFORMATION": "Información",
            "SKILLS": "Habilidades",
            "PROFILE_PHOTO": "Foto de perfil",
            "UPLOAD_AN_IMAGE": "Subir una imagen",
            "REMOVE_PHOTO": "Quitar foto",
            "PREFRRED_SIZE_250_BY_250_PIXEL": "* Tamaño preferido 250 por 250 píxeles (.png o jpg)",
            "PLEASE_PROVIDE_YOUR_PROFILE_PHOTO": "Proporcione su foto de perfil",
            "PLEASE_PROVIDE_PROPER_SIZE_PROFILE_PHOTO": "Proporcione una foto de perfil del tamaño adecuado.",
            "FIRST_NAME": "Primer nombre",
            "PLEASE_PROVIDE_FIRST_NAME": "Por favor proporcione su nombre",
            "COUNTRY": "País",
            "PLEASE_SELECT_COUNTRY": "Por favor seleccione país",
            "PLEASE_PROVIDE_COUNTRY": "Indique el país",
            "STATE": "Estado",
            "PLEASE_SELECT_STATE": "Seleccione el estado",
            "PLEASE_PROVIDE_STATE": "Por favor proporcione el estado",
            "CONTECT_NUMBER": "Número de contacto",
            "PLEASE_PROVIDE_CONTACT_NUMBER": "Proporcione el número de contacto",
            "PLEASE_PROVIDE_VALID_CONTACT_NUMBER": "Proporcione un número de contacto válido",
            "JOB_DESCRIPTION": "Descripción del trabajo",
            "PLEASE_PROVIDE_YOUR_JOB_DESCRIPTION": "Proporcione la descripción de su trabajo",
            "TOTAL_YEARS_OF_EXPERIENCE": "Años totales de experiencia",
            "SELECT_YOUR_TOTAL_EXPERIENCE": "Seleccione su experiencia total",
            "PLEASE_PROVIDE_YOUR_EXPERIENCE": "Proporcione su experiencia",
            "PROFILE": "Perfil",
            "SELECT_YOUR_PROFILE": "Seleccione su perfil",
            "PLEASE_PROVIDE_YOUR_PROFILE": "Por favor proporcione su perfil",
            "SELECT_YOUR_SKILL": "Seleccione su habilidad",
            "PLEASE_PROVIDE_YOUR_SKILL": "Por favor proporcione su habilidad",
            "RATE_YOUR_SKILLS": "Califica tus habilidades",
            "UPLOAD_PORTFOLIO": "Subir portafolio",
            "UPLOAD": "Subir",
            "DRAG_AND_DROP_FILES_HERE": "Arrastra y suelta archivos aquí",
            "PLEASE_PROVIDE_YOUR_PORTFOLIO": "Por favor proporcione su portafolio",
            "SAVE_AND_REGISTER": "Guardar y registrarse",

            "KNOW_OUR": "Conozca ",
            "SOLUTIONS": " nuestras soluciones",
            "WHAT_IS_APP": "¿Qué es una App?",
            "APPS_ARE_NOTHING_MORE_THEN_SOFTWARE_TOOLS_WRITTEN_IN": "Las apps no son más que herramientas de software escritas en distintos lenguajes de programación, caracterizadas por ser útiles, dinámicas, fáciles de instalar y manejar.",
            "BOOT_YOUR_MARKETING_STRATEGIES_BY_CREEATINGYOUR_OWN": "Impulsa tus estrategias de marketing creando tu propia aplicación móvil. Teniendo una propuesta innovadora para tu compañía crearas la oportunidad a tus clientes de interactuar de una manera más dinámica y atractiva con tu compañía.",
            "MAKE_A_DIFFERENCE_AND_BECOME_A_REFERENCE": "¡Haz la diferencia y conviértete en una referencia!",
            "APPS_MADE_BY_US": "Apps hechas por nosotros",
            "WE_CREATE_APPS_FOR_YOU": "¡Creamos Apps para ti!",
            "QUOTE_YOUR_APPS": "Cotice su APP",
            "START_NOW": "Start now!",
            "MARKETING_APPS": "Marketing Apps",
            "AT_DESTINY_WE_HAVE_A_TEAM_OF_EXPERT_DEVELOPERS": "En destiny contamos con un equipo de desarrolladores expertos en productos digitales que utilizan un enfoque basado en el usuario, garantizando así la usabilidad y facilidad de interacción del usuario con tus productos digitales, creando una experiencia única e innovadora que dejará huella.",
            "TESTING_QA": "Testing / QA",
            "WE_OFFER_YOU_A_CONSTANT_PROCESS_WHERE_WE_WILL": "Te ofrecemos un proceso constante donde garantizamos que tu producto funcione de manera eficiente y con altos estándares de calidad que minimizaran los ajustes al momento de la producción.",
            "ENTERPRISE_SOLUTIONS": "Soluciones Empresariales",
            "WE_WORK_HAND_IN_WITH_OUE_CLIENTS_IN_THE": "Trabajamos de la mano con nuestros clientes en el desarrollo de software personalizado, perfeccionando los procesos internos de las compañías en áreas como: ventas, mercadeo, operaciones, gerencia, entre otras.",
            "APP_TYPES": "Tipos de aplicaciones",
            "FOR_US_IT_IS_IMPORTANT_TO_PROVIDE_APPS": "Para nosotros es importante proporcionar aplicaciones que se ajusten a las necesidades del cliente.",
            "APPS_AND_DATA": "¡Aplicaciones y análisis de datos!",
            "MAINTAINING_A_STATISTICAL_ANALYSIS_CAN_GIVE": "Mantener un análisis estadístico puede brindarle la oportunidad de encontrar fácilmente áreas de mejora y fortalecer aquellas que ya funcionan. ¡Conoce tu empresa y llévala a otro nivel!",
            "APPS_FOR_E_COMMERCE": "¡Apps para E-commerce!",
            "IT_IS_IMPORTANT_TO_KEEP_YOUR_USERS": "Es importante mantener a tus usuarios actualizados acerca de tu empresa por medio de tu APP. ¡Dale la oportunidad a tu compañía de crecer y ganar un mayor número de clientes potenciales!",
            "APPS_FOR_ENTREPREEURS": "¡Apps para emprendedores!",
            "THE_WORLD_BELONGS_TO_THOSE_WHO_UNDERTAKE": "El mundo es de aquellos que emprenden y confían en su creatividad. Miles de personas apuestan en el mundo de los negocios a través de su StartUP, superando los 1.000 millones de dólares en valoración. ¡Atrévete a cumplir tus sueños!",
            "APPS_FOR_COMPANIES_OR_B2B": "¡Apps para empresas o servicios B2B!",
            "WE_KNOW_THAT_EACH_COMPANY_HAS_DIFFERENT": "Sabemos que cada compañía cuenta con necesidades diferentes y áreas de oportunidad a tratar, por eso ofrecemos una asesoría completamente personalizada y enfocada a cada una de las metas de la compañía para el desarrollo de la APP, consiguiendo así la dinamización del proceso con el objetivo de optimizar la venta de productos y/o servicios de la compañía.",
            "APPS_AND_MEDIA": "Apps and media!",
            "STAY_INFORMED": "Stay informed. May important news always go with you. Can you imagine being able to promote all your informative content through an APP? Do not lose this chance!",
            "APPS_FOR_RESTAURENTS": "¡Aplicaciones para restaurantes!",
            "THE_RESTAURENT_INDUSTRY_HAS_ALREADY": "La industria de restaurantes ya se unió a la era digital. ¡No te quedes atrás!. Dale a tu negocio la oportunidad de optimizar su atención al cliente.",
            "DO_YOU_HAVE_ANY_DOUBT": "¿Tienes alguna duda?",
            "CONTACT_US": "Comunícate con nosotros",

            "CATEGORIES": "Categorías",
            "EXPLORE_AND_LEARN_ABOUT_THE_DIFFERENT": "Explore y conozca los diferentes servicios que ofrecemos.",
            "SERVICES_WE_OFFER": "servicios que ofrecemos.",
            "CLOSER_COMMUNICATION_STRATEGIES_WITH_CUSTOMERS": "Estrategias de comunicación más cercana con los clientes, contacto directo y asertivo con los usuarios actuales o prospectos futuros.",
            "PIECE_OF_DIGITAL_ADVERTISING_THAT_MIXES": "Pieza de publicidad digital que mezcla imágenes, texto y en ocasiones contenido interactivo como audio y animación.",
            "SOFTWARE_TOOLS_WRITTEN_IN_DIFFERENT_PROGRAMMING": "Herramientas de software escritas en diferentes lenguajes de programación, caracterizadas por ser útiles, dinámicas, fáciles de instalar y manejar.",
            "IT_IS_THE_ESSENCE__TO_GENERATE_A_BETTER": "Es la esencia de la estrategia generar una mejor interacción, brindando información importante para el usuario.",
            "THEY_ARE_ONE_OF_THE_MOST_EFFETIVE_WAYS": "Son una de las formas más efectivas de presentar una empresa al cliente, mostrando no solo la esencia sino también el propósito de la misma.",
            "IT_ALLOWS_THE_TRADE_OF_DEFFERENT_PRODUCTS_AND": "Permite el comercio de diferentes productos y servicios de medios digitales, como páginas web, aplicaciones móviles y redes sociales.",
            "USING_THIS_SERVICE_WILL_RESULT_IN_AN": "El uso de este servicio dará como resultado un contacto ágil y rápido con sus clientes actuales o potenciales.",
            "THEY_ARE_THOSE_NOTIFICATIONS_THAT_APPEAR_ON_USERS": "Son aquellas notificaciones que aparecen en los dispositivos de los usuarios cuando visitan un sitio web, o de repente en sus teléfonos móviles.",
            "DEFINE_FUNCTIONAL_SCOPE_OF_YOUR_PROJECT": "Defina el alcance funcional de su proyecto para planificar el presupuesto y el cronograma del proyecto.",
            "WE_UNDERSTAND_ONE_SIZE_DOESNOT_FIT_ALL": "Entendemos que una talla no sirve para todos. Podemos trabajar con usted para definir y desarrollar una solución personalizada que satisfaga sus necesidades.",
            "SEE_MORE": "Ver más",
            "LEARN_MORE_GALLERY": "Más información",

            "WHAT_IS_AN_SMS": "¿Qué es un SMS?",
            "AN_SMS_IS_A_SERVICE_AVAOLABLE_ON": "Un SMS es un servicio disponible en los teléfonos móviles que permite el envío de mensajes cortos entre celulares.",
            "USING_THIS_SERVICE_WILL_RESULT": "Utilizar este servicio te dará como resultado un contacto ágil y rápido con tus actuales o potenciales clientes. ¡Empieza ya a usarlo como estrategia!",
            "GUARANTEED_RESULTS": "¡Resultados Garantizados!",
            "SMS_IS_A_GOOD_AND_EXCELLENT_": "Los SMS son un buen excelente canal de repartición a la hora de querer entregar un mensaje a corto plazo, tienen una de las tasas de apertura y participación más altas entre todos los canales utilizados en el mercado.",
            "WHY_USE_SMS_AN_A_STRATEGY": "¿Por qué utilizar los SMS como estrategia?",
            "IF_YOU_WANT_TO_INCREASE": "Si quieres incrementar las ventas y visitas de una manera rápida y constante.",
            "SMS_COUPONS_HAVE_BEEN_SHOWN": "Se ha demostrado que los cupones por SMS son muy eficaces para impulsar las ventas e incrementar los ingresos. Los clientes canjean los cupones enviados por mensaje de texto 10 veces más que otros cupones, enviados a través de otros medios.",
            "THE_CLICK_RATE_FOR_SMS": "La tasa de clic de los URL de SMS es un 19%, la cual la posiciona entre una de las más elevadas con respecto a otros medios.",
            "USE_SMS_TO_LINK_ONLINE": "Utilice SMS para unir lo online y lo offline mediante el envío de ofertas, y así lograr las visitas en persona a su negocio.",
            "IT_IS_A_GREAT_SOURCE_OF": "Es una gran fuente de fidelización y generación de leads importantes para la compañía.",
            "THE_NUMBERS_DONT__LIE": "Los números no mienten",
            "OF_SMS_ARE_READ_BY_THE_USER": "de los SMS son leídos por el usuario.",
            "OF_SMS_ARE_READ_IN_THE_FIRST_3_MINUES": "de los SMS son leídos en los 3 primeros minutos de ser recibidos.",
            "OF_USERS_USE_SMS_CONFIRM_THAT_IT_IS_AN": "de los usuarios usan SMS y confirman que es un medio efectivo.",
            "OF_PEOPLE_USE_CELL_PHONES": "de las personas utiliza celulares.",
            "BET_ON_FAST_AND_EFFECTIVE_COMMINICATION": "Apuesta por una comunicación rápida y eficaz con el usuario",
            "YOU_WILL_ALWAYS_ACHIEVE_THE_BEST_RESULT": "Siempre conseguirá los mejores resultados en el menor tiempo estimado.",

            "WHAT_IS_A_BANNER": "¿Qué es un Banner?",
            "A_BANNER_IS_A_PIECE_OF_DIGITAL_ADVERTING": "Un banner es una pieza de publicidad digital que mezcla imágenes, texto y en algunas ocasiones contenido interactivo como audio y animación, este se incluye en páginas web para hacer más atractiva y visible la imagen de una empresa o campaña. Usualmente el banner tiene como objetivo invitar al usuario a interactuar por medio de un clic que dirigirá a la persona a una página promocional.",
            "BANNER_ARE_ONR_OF_THE_MOST_USED_ELEMENTS": "Los banners son uno de los elementos más utilizados para la monetización de una página web a través de publicidad, por lo cual es usual verlos en portales con mucho tráfico o que están especializados en algún contenido puntual.",
            "WHAT_SHOULD_YOUR_BANNER_HAVE": "¿Qué debe tener tu banner?",
            "FOR_THE_PIECE_TO_BE_COMPLETELY_EFFICIENT": "Para que la pieza sea completamente eficiente y efectiva debe contener algunos elementos como:",
            "CELL_TO_ACTION": "Call to action",
            "CTA": "(CTA)",
            "BANNER_DIMENSIONS": "Dimensiones del banner.",
            "KEEP_USER": "Keep user",
            "CAPTIVE": "captive",
            "ORIGINAL_AND_CREATIVE": "Contenido e imagen original y creativa.",
            "CONTENT_AND_IMAGE": "original y creativa.",
            "URL": "URL",
            "IDENTIFICATION": "Identificación del URL",
            "SHOWINESS": "Vistosidad",
            "AND_ELEGANCE": "y elegancia.",
            "SHAPES": "Formas",
            "BANNER_SHAPES": "Formas del banner.",
            "MOVEMENT": "del banner.",
            "AND_ACTION": "y acción",
            "MOVEMENT_AND_ACTION": "Movimiento y acción.",
            "A_GOOD": "Una buena",
            "POSITION": "posición",
            "IT_SHOULD_LOOK_PROFESSIONAL": "Debe verse profesional",
            "IT_SHOULD_LOOK_PROFESSIONAL_AND_TRUSTWORTH": "Debe verse profesional y confiable.",
            "AND_TRUSTWORTH": "y confiable.",
            "TYPE_OF_BANNERS": "Tipo de Banners",
            "STEALER": "Robapáginas:",
            "DONT_BE_FOOLED_BY_ITS_NAME_THIS": "Que no te engañe su nombre! Este formato busca siempre integrarse al diseño y estilo de la página manteniendo así la armonía de la misma sin resultar invasivo. Su formato puede ser horizontal o vertical y su tamaño puede variar entre 300 x 250 píxeles hasta los 300 x 600 píxeles.",
            "RECTANGULAR": "Rectangular:",
            "IT_IS_A_FORMAT_WITH_A": "Es un formato con orientación horizontal, y es comúnmente el más utilizado. En muchas ocasiones este tipo de banner se encuentra ubicado en el tercio superior de la página.",
            "THERE_ARE_THREE_TYPES": "Existen tres tipos:",
            "THE_MEGABANNER": "El megabanner: Este puede tener mas de 900 x 90 píxeles.",
            "THE_STANDARD_BANNER": "El banner estándar: Este puede tener 468 x 60 píxeles",
            "THE_MIDDLE_BANNER": "El medio banner: Este puede tener 234 x 60 o 234 x 90 píxeles.",
            "SKYSCRAPER": "Rascacielos:",
            "THIS_BANNER_HAS_A_RECTANGULAR_FORMAT_WITH": "Este banner tiene un formato rectangular con orientación vertical y está normalmente ubicado en las columnas laterales de una página web. Su tamaño es 100 x 600 o 120 x 700 píxeles.",
            "EXPANDABLE": "Expandibles:",
            "THESE_HAVE_SIMILAR_FORMATES_BUT_HAVE_THE": "Estos tienen formatos similares, pero cuentan con la capacidad ampliarse en la pantalla de modo que ocupan gran parte de ella, esta opción varía en función del medio donde se encuentre el banner. Normalmente, este tipo de pieza se despliega al pasar el puntero del mouse por encima o al entrar al sitio. Al ser tan invasivo debe tener un botón de cierre lo suficientemente visible para el usuario, esto es recomendado por la IAB.",
            "INTERSTITIAL": "Intersticial:",
            "THIS_BANNER_FILLS_THE_ENTIRE_BROWSER": "Este banner ocupa toda la pantalla del navegador. Su eficacia es de un porcentaje muy alto ya que capta la atención del usuario de una manera inmediata, pero al ser un formato tan invasivo se recomienda darle un uso controlado.",
            "LAYER_OR_LAYER": "Layer o capa:",
            "THIS_FORMAT_CONTAINS_AN_ANIMATED_IMAGE": "Este formato contiene una imagen animada en constante movimiento sobre el contenido de la página, y aunque tiene grandes resultados al tratar de captar la atención y generar CTR, resulta altamente intrusivo y debe ser manejado de una manera moderada.",
            "BANNER_MADE_BY_US": "Banner hechos por nosotros.",
            "LEAVE_YOUR_FIRST_IMPRESSION_IN_THE_HANDS": "Deja tu primera impresión en manos de expertos y no",
            "AND_DONT_MISS_THE_OPPORTUNITY": "pierdas la oportunidad de hacer crecer tu negocio.",

            "WHAT_IS_AN_EMAIL": "¿Qué es un Email?",
            "AN_EMAIL_IS_ONE__OF_THE_closest": "Un Email es una de las estrategias de comunicación más cercana con los clientes, el contacto directo y asertivo con los usuarios actuales o futuros prospectos es una gran oportunidad para garantizar la fidelización, no dejes pasar la oportunidad de garantizar la continuidad de tus suscriptores y así poder brindar una información constante y actualizada de cada uno de los productos y servicios que ofrece tu compañía.",
            "EMAIL_MARKETING_AS_A": "¡El email marketing como estrategia!",
            "DO_NOT_LOSE_YOUR_HEAD_TRYING": "No pierdas la cabeza tratando de hacer llegar los mensajes a tus clientes, nuestros expertos en el área se encargarán directamente de hacer llegar los correos electrónicos a la bandeja de entrada de cada uno de los usuarios, dando un soporte y asesoría constante, adaptando así las estrategias y capacidad de entrega enfocadas en cada una de las necesidades del cliente.",
            "DESTINY_OFFERS_YOU_A_CPMLETE": "Destiny te ofrece una consultoría completa para mejorar las campañas, la administración de tus listas, el alcance de entrega de los email y por supuesto la estrategia general de marketing para obtener los mejores resultados en tu negocio.",
            "CUSTOMIZE_YOUR_CAMPAIGNS": "¡Personaliza tus campañas!",
            "WE_WILL_CONDUCT_A_COMPLETE_STUDY_OF_YOUR": "Realizaremos un estudio completo de tu programa actual de correo electrónico, creando así el mejor punto de partida para que nuestros expertos mejoren el rendimiento y aumenten la obtención de clientes potenciales, el ROI, o los indicadores de rendimiento más importantes para tu negocio.",
            "EMAIL_MADE_BY_US": "Email hechos por nosotros",
            "VER_MAS": "Ver más",
            "EVERYTHING_ENTERS": "¡Todo entra",
            "THROGH_THE_EYES": "por los ojos!",
            "WE_HAVE_THE_BEST_TEAM_OFDESIGNERS": "Contamos con el mejor equipo de diseñadores y creadores de contenido para que tus email siempre luzcan atractivos y profesionales, creando así una manera más eficaz y llamativa de entregar tus mensajes al usuario.",
            "THE_NUMBER_DONT_LIE": "¡Los números no mienten!",
            "WE_WORK_WITH_OUR_CLIENTS_TO_DEVELOP": "Trabajamos con nuestros clientes para desarrollar productos digitales y ofrecerles las respuestas que necesitan según sus necesidades.",
            "FROM_RESEARCH_TO_IMPROVE_THE": "Desde la investigación de estrategias para mejorar la experiencia del cliente con tecnologías digitales orientadas a promover",
            "ADVANTAGE_THAT_GENERATE_VALUE_FOR": "ventajas competitivas que generen valor para el cliente.",
            "AT_DESTINY_WE_REACHED_90_DEVIVERABILITY": "En Destiny llegamos a un 90% en entregabilidad, superando así el promedio de la industria que actualmente se encuentra en un 85%.",
            "WE_HAVE_AN_AVARAGE_MONTHLY_VOLUME": "Contamos con un volumen de 15k mensual promedio solo de nuestros clientes de servicios de entregabilidad.",
            "THE_AVARAGE_BOUNCE_RATE_OF_OUR": "La tasa rebote promedio de nuestros clientes actuales es del 1%, superando el 2% de la tasa promedio que tiene hoy la industria.",
            "WE_DELIVER_YOU": "Te entregamos:",
            "WELCOME_EMAIL_FOR_NEW_SUBSCRIBERS": "Correo electrónico de bienvenida para nuevos suscriptores.",
            "A_SERIES_OF_ACTIVATED_EMAILS": "Una serie de correos electrónicos activados.",
            "THE_SEGMENTATION_OD": "La segmentación de tus contactos.",
            "REPLYING_OF_EMAILS": "Reenvío de correos electrónicos los cuales no hayan sido abiertos.",
            "POWERFUL_LIST_BUILDING": "Potentes herramientas de creación de listas",
            "ALL_OF_THIS_THROUGH_OUR_SIGNIFICANT": "Todo esto a través de nuestra importante experiencia en",
            "AT_DESTINY_WE_WANT_TO_CREATE_YOUR": "En Destiny queremos crear tus",
            "MESSAGE_AND_BE_PART": "mensajes y ser parte de tu equipo de trabajo.",
            "QUOTE_YOUR_EMAIL": "Cotiza tu email",



            "DO_YOU_NEED_A_LANDING_PAGE": "¿Necesitas una Landing Page?",
            "THE_SUCCESS_OF_YOUR_BUSINESS": "El éxito de tu negocio va estrechamente ligado a la cantidad de Leads y ventas efectivas que puedas generar. Una Landing Page hace parte esencial de la estrategia para lograrlo, generando una mejor interacción y brindando una amplia base de datos para tú compañía. Una landing page, es una estrategia en la cual los usuarios llegan de manera directa después de seguir un enlace que puede estar en redes o tú website. En e-marketing nos referimos a esta como una página web independiente del sitio oficial donde podemos brindar información y atención inmediata e intuitiva, que tiene como resultado una mejor experiencia con el usuario.",
            "THE_MAIN_OBJECTIVE_OF_A_LANDING_PAGE_IS_TO": "El objetivo principal de una landing page es convertir la interacción con el usuario en una venta efectiva o en una manera rápida de recolección de datos, proporcionando así el aumento del tráfico de tu web y la visibilidad de tu marca.",
            "WHAT_SHOULD_YOUR_LANDING_PAGE_HAVE": "¿Qué debe tener tu Landing page?",
            "A_DEFINED_GOAL": "Un objetivo definido",
            "A_LANDING_HAS_A_SINGLE_OBJECTIVE": "Un aterrizaje tiene un único objetivo. Siempre estará dirigido a una audiencia concreta, un momento intencionado y una imagen impactante, con el objetivo de convertir al usuario en una venta eficaz.",
            "LAYOUT": "Diseño",
            "THE_FASTEST_WAY_TO_CAPTURE_THE_ATTENTION": "La forma más rápida de captar la atención de los usuarios o potenciales clientes está directamente ligada a la imagen, color y estilo del diseño, creando así un factor diferenciador que hará referencia a la intención de la empresa de mostrar una propuesta de valor. y coherencia para el usuario.",
            "CALL_TO_ACTION": "Llamada a la acción",
            "IT_REFERS_TO_THE_INTENTION_OF_ACTION_OF": "Se refiere a la intención de acción del usuario con la marca, esto lo logramos creando una clara invitación a la interacción, utilizando contenido valioso que sea de interés para el usuario como medio.",
            "INCREASE_YOUR_LEADS_WITH": "Aumenta tus leads con",
            "A_LANDING_PAGE": "una Landing page:",
            "THE_MAIN_OBJECTIVE_OF_A_LANDING": "El principal objetivo de una landing es darle al usuario una información e imagen coherente para captar su atención inmediata. Estas al ser fuentes rápidas y contundentes de información te darán mejores resultados en el momento del cierre de una compra, sin diluir expectativa como normalmente sucede en los website, ya que los contenidos extensos de información pueden distraer al usuario de su objetivo principal y su intención de compra.",
            "LANDING_PAGE_MADE_BY_US": "Langing page hechas por nosotros.",
            "THE_NUMBERS_DONOT_LIE": "¡Los números no mienten!",
            "WE_WORK_OUR_CLIENTD_TO_DEVELOP_DIGITAL": "Trabajamos con nuestros clientes para desarrollar productos digitales y ofrecerles las respuestas que necesitan según sus ",
            "FORM_RESEARCH_STATEGIES_TO_IMPROVE_THE": "necesidades. Desde la investigación de estrategias para mejorar la experiencia del cliente con tecnologías digitales",
            "ADVANTAGES_THAT_GENERATE_VALUE": "orientadas a promover ventajas competitivas que generen valor para el cliente.ts.",
            "WEBSITES_WITH_30_OR_MORE_LANDING": "Los websites con 30 o más Landing pages consiguen un 70% más de Leads y resultados que aquellos que sólo poseen 10.",
            "OF_MARKETING_STRATEGISTE_SEND": "El 66% de estrategas de marketing envían sus link de campaña hacia Landing pages garantizando así mayores posibilidades de obtener buenos resultados en la conversión.",
            "COMPANIES_THAT_INCREASE_THEIR_LANDING_PAGES": "Las compañías que aumentan de 10 a 15 sus Landing pages aumentan en un 55% su capacidad de generar leads.",
            "QUOTE_YOUR_LANDING_PAGE": "Cotiza tu Landing page",
            "IF_YOUR_GOAL_IS_TO": "Si tu objetivo es aumentar el alcance y acelerar las ventas en tu compañía no dudes en implementar una Landing page.",
            "AT_DESTINY_WE_WILL_GIVE": "En Destiny te daremos la mejor asesoría y garantizamos la obtención de mejores resultados.",

            "WHAT_IS_AN_E_COMMERCE": "¿Qué es un E-commerce?",
            "E_COMMERCE_IS_AN_ECONOMIC_ACTIVITY": "El E-commerce es una actividad económica que permite el comercio de diferentes productos y servicios a partir de medios digitales, como páginas web, aplicaciones móviles y redes sociales. Por medio de internet, los clientes pueden acceder a diversos catálogos de marcas, servicios y productos, en todo momento y en cualquier lugar.",
            "AT_DESTINY_WE_TAKE_CARE_OF": "En destiny nos encargamos de evaluar tu negocio dando así una articulación y enfoque a las expectativas del mismo, resolviendo todos los desafíos y metas del cliente a través de una amplia asesoría por parte de nuestro equipo especializado en E-commerce, dando como resultado estrategias claras, procesables y efectivas que mejoraran la marca, la experiencia y las ventas de tu compañía.",
            "WHY_ARE_WE_AN_IDEAL": "¿Qué debes ",
            "PARTNER_FOR_E_COMMERCE": " ideal para los servicios de",
            "CONSULTING_SERVICES": "consultoría en E-commerce?",
            "WE_HAVE_7_YEARS_OF_EXPERIENCE": "Contamos con 7 años de experiencia y certificados que nos posicionan como una compañía de excelentes resultados",
            "WE_HAVE_THE_PRESENCE_OF_CONSULTING_": "Contamos con la presencia de consultores y desarrolladores especializados en el área de comercio electrónico que brindaran la mejor asesoría y soporte a nuestros clientes.",
            "WE_ARE_SPECIALIZED_IN_THE_MOST": "Somos especializados en las plataformas más utilizadas en el mercado, entre ellas, WooCommerce, PrestaShop, Magento, Shopify y VTEX​.",

            "OUR_SERVICES": "Nuestros servicios:",
            "DESING_AND_PLANING_SUPPORT": "Soporte de diseño y planificación.",
            "WE_ANALYZE_THE_TARGET": "Analizamos el público objetivo.",
            "WE_IDENTIFY_AND_DEFINE_THE_PRICIING": "Identificamos y definimos la estrategia de precios e inventario.",
            "WE_PLAN_AND_DESIGN_AN_E_COMMERCE": "Planificamos y diseñamos una hoja de ruta de comercio electrónico y TI.",
            "WE_DEFINE_AND_DESIGN_THE_USER_JOURNEY": "Definimos y diseñamos el recorrido del usuario.",
            "WE_IDENTIFY_SYSTEM_INTEGRATIONS": "Identificamos integraciones de sistemas.",
            "WE_DESIGN_THE_CUSTOMER_EXPERIENCE": "Diseñamos la estrategia de gestión de la experiencia del cliente.",
            "WHAT_DO_YOU_NEED_TO_KNOW_TO_MAKE_A_GOOD_E_COMMERCE": "¿Qué debes saber para hacer un buen E-commerce?",
            "WHAT_DO_YOU_NEED_TO": "¿Qué debes saber para hacer un buen E-commerce?",
            "KNOW_TO_MAKE_A_GOOD": "saber para hacer un buen",
            "E_COMMERCE": " E-commerce?",
            "IDENTIFY_THE_IDEAL_TECHNOLOGICAL_SOLUTION_FOR_YOUR": "Identificar la solución tecnológica ideal para tus objetivos de comercio electrónico.",
            "E_COMMERCE_PLATFORM": "Recomendación de la plataforma de comercio electrónico.",
            "CHOOSE_THE_MOST_OPTIONAL_AND_COST": "Elegir la solución de servidor en la nube más óptima y rentable.",
            "IDENTIFY_CHANNELS_FOR_CUSTOMER": "Identificar canales para la gestión de la relación con el cliente.",
            "USABILITY_AND_PERFORMANCE_TESTS_OF": "Pruebas de usabilidad y rendimiento del E-commerce existente.",
            "THE_SECRET_OF_A": "El secreto de un",
            "SUCCESFUL_E_COMMERCE": "E-commerce exitoso",
            "ONLINE_SALES_IN_RECENT_MONTHS_HAVE_GROWN_BU_300": "Las ventas online en los últimos meses han tenido un crecimiento del 300% en América Latina. La situación a nivel mundial en consecuencia del COVID-19 ha tenido como resultado un incremento exponencial en los usuarios que realizan compras online, por lo cual todas las compañías en el mundo buscando una manera de reposicionarse y adaptarse al cambio, han tomado la decisión de implementar como estrategia el comercio electrónico, logrando así incrementar sus ventas y mejorar la experiencia con los clientes a través de sus canales digitales.",
            "NO_MODE_INFORMALITY": "¡No más informalidad!",
            "SECURITY_AND_EASE_ARE_TWO_KEY_ELEMENTS_THAT": "La seguridad y la facilidad son dos elementos clave que las empresas deben transmitir a los usuarios, por lo que el comercio electrónico debe contar con las medidas de seguridad necesarias para construir una buena relación y experiencia con sus clientes.",
            "THE_IMPORTANCE_OF": "La importancia de",
            "GOOD_REFERANCE": "buenas referencias",
            "TO_ACHIEVE_A_GREATER_RAPPROCHEMENT": "Para lograr un mayor acercamiento con el usuario, se deben seguir y conocer las buenas prácticas de grandes empresas como Amazon, Alibaba o Mercado Libre en cuanto a su flujo de compra.",
            "THE_UNITY_BETWEEN_PHYSICAL": "La unidad entre lo físico",
            "AND_DIGITAL_SPACES": "y espacios digitales",
            "RELATIONSHIP_CHANNERS_MUST_HAVE_A": "Los canales de relación deben tener una conexión y trabajar juntos. En este caso, la omnicanalidad juega un papel importante para lograr una comunicación fluida y unificada entre el usuario y la empresa.",
            "MAKE_BUSINESS_DECISIONS": "¡Tome decisiones comerciales buscando un factor diferenciador!",
            "LOOKING_FOR_A_DEFFERANTIATOR": "buscando un factor diferenciador!",
            "AT_DESTIUNY_WE_WILL_HELP_YOU_UNLOCK": "En Destiny te ayudaremos a desbloquear nuevas oportunidades para desarrollar una estrategia sólida de experiencia del cliente, aprovechando cada una de las herramientas digitales útiles para generar estrategias comerciales eficientes.",
            "E_COMMERCE_MADE_BYUS": "E-commerce hechos por nosotros",
            "MOVE_WITH_THE_WORD_AND_ADAPT": "Muévete con el mundo y adáptate a los cambios.",
            "AT_DESTINY_WE_WILL_HELP_YOU": "En Destiny te ayudaremos a crecer sin que tus clientes se muevan de casa.",

            "WHAT_IS_A_WEBSITE": "¿Qué es un Website?",
            "WEB_PAGES_TODAY_ARE_ONE_OF_THE_MOST": "Las páginas web hoy en día son una de las maneras más efectivas de presentar una empresa al cliente, mostrando no solo la esencia sino también el propósito de la misma, dando así mismo un mejor primer punto de contacto con el usuario a través de internet.",
            "AT_DESTINY_WE_HAVE_A_TEAM_OF_EXPERTS_IN_WEB": "En Destiny tenemos un equipo expertos en diseño web que plasmará de la mejor manera la esencia e imagen de tu marca teniendo siempre como objetivo la funcionalidad del sitio, presentando ante el usuario un sitio agradable y fácil de navegar.",
            "OUR_HIGHLY_TRAINED_STAFF_WILL_BE_IN_CHARGE_OF": "Nuestro personal altamente capacitado se encargará del contenido, diseño y programación logrando así la unificación y funcionalidad de la página. Contamos también con un equipo de soporte especializado para que tu website siempre funcione de una manera eficiente y constante.",
            "WHAT_SHOULD_YOUR_WEBSITE_HAVE": "¿Qué debe tener tu Website?",
            "IT_MUST_BE_RESPONSIVE": "Debe ser responsive",
            "THE_WORLD_IS_GOING_FASTER_AND_FASTER_USERS": "El mundo va cada vez más rápido y los usuarios están en constante movimiento, por lo que los dispositivos como, smartphones, Tablet y demás han tomado una posición importante como fuentes de información efectiva y rápida representando hoy gran parte tráfico de internet, por lo cual los Website deben moverse al igual que el usuario. Una página responsive asegura que el mensaje llegue de la manera correcta a los usuarios, no importa desde donde los visiten.",
            "WE_ASSURE_OUR_CLIENTD_SITES_THAT_ARE_FULLY_": "Nosotros aseguramos a nuestros clientes sitios completamente adaptables a cada dispositivo, dando así un mejor alcance y versatilidad a la hora de tener ese primer contacto con sus clientes.",
            "UI": "UI",
            "IT_ALLOWS_GUIDING_USERS_THROUGH_NAVIGATION": "Permite guiar a los usuarios a través de la navegación y llevándolos a tomar acción de una manera rápida e intuitiva.",
            "UX": "UX",
            "THE_USERS_EXPERIENCE_WILL_ALWAYS_BE_THE_BEST": "La experiencia del usuario siempre será el mejor referente para mejorar la usabilidad del sitio web, basándonos siempre en las buenas prácticas y normativas que nos permitirán ofrecer siempre una buena experiencia.",
            "CMS_PLATFORMS": "Plataformas CMS",
            "A_CMS_IS_A_SYSTEM_THAT_ALLOWS_US_TO_CREATE_A": "Un CMS es un sistema que nos permite crear un sitio web y poder administrarlo de una forma sencilla, a través de una interfaz de administración. Con esta interfaz podrás insertar o modificar textos, imágenes y otros aspectos del sitio web, dándote la capacidad de hacerlo por medio de plantillas predeterminadas. De esta manera podrás hacer y editar tu propio website sin necesidad de tener conocimientos en desarrollo y diseño web. Es importante tener en cuenta que todos los sitios no son iguales, todos tendrán un objetivo diferente, por lo cual debes tener muy claro cual es el objetivo del sitio web y junto con nosotros elegir la solución más adecuada a tus necesidades.",
            "E_COMMERCE_MADE_BY_US": "Website hechos por nosotros.",
            "SHOULD_YOU_INVERT_IN_QUALITY_WEB_DESIGN": "¿Deberías invertir en diseño web de calidad?",
            "THE_NUMBERS_DONT_LIE": "¡Los números no mienten",
            "OF_USERS_DO_NOT_INTERACT_WITH_A_WEBSITE": "de los usuarios no interactúan con un website si el diseño no es atractivo.",
            "OF_USERS_BROWER_THE_PRODUCES_SERVICES_PAGE": "de los usuarios navega la página de productos/servicios antes que en cualquier otra de un sitio web.",
            "OF_USERS_BROWER_USEING_THEIR_SMART_PHONE": "de usuarios navega un website mediante su smartphone (teléfono móvil).",
            "ARE_YOU_INTERESTED_IN_CREATING_YOUR_OWN": "¿Estás interesado en crear tu propio Website?",
            "DESTINY_OFFERS_YOU_THE_BEST_ADVICE_AND_SERVICE": "Destiny te ofrece la mejor asesoría y servicio para que hagas realidad tu sueño",
            "OF_HAVING_YOUR_OWN_WEBSITE_COME_TRUE": "de tener tu propia Página web.",

            "WHAT_ARE_PUSH_NOTIFICATIONS": "¿Qué son las Push notifications?",
            "THEY_ARE_THOSE_NOTIFICATIONS_THAT_APPEAR_ON": "Son esas notificaciones que aparecen en los dispositivos de los usuarios cuando visitan un sitio web, o repentinamente en sus teléfonos móviles.",
            "WHAT_ARE_THEY_FOR": "¿Para qué sirven?",
            "PUSH_NOTIFICATIONS_ARE_USED_TO_PROMOTA": "Las push notifications sirven para promocionar algún producto y/o servicio.",
            "THEY_INVITE_YOU_DIRECTLY_TO_THE": "Te invitan directamente a la suscripción.",
            "THEY_SERVE_TO_REPORT_AN": "Sirven para informar algún acontecimiento importante o mensaje urgente, un ejemplo, el uso de tus tarjetas y cuentas bancarias.",
            "EXAMPLE_THE_USE_OF": "ejemplo, el uso de sus tarjetas y cuentas bancarias.",
            "THE_4_PUSH": "Las 4 push",
            "USER_MESSAGES": "Mensajes de usuario:",
            "LET_SOMEONE_KNOW_WHEN": "Hazle saber a alguien cuando se menciona su nombre de usuario en un hilo o si alguien le envía un mensaje directo a través de tu aplicación.",
            "TRANSACTIONAL_NOTIFICATION": "Notificación transaccional:",
            "BUILD_USER_LOYALTY_BY": "Genera lealtad en el usuario configurando notificaciones automáticas activadas por eventos. Por ejemplo, las facturas próximas a vencer, la entrega de sus paquetes, cambios en sus itinerarios de vuelo, entre otra cantidad de mensajes que pueden ser útiles y de gran ayuda para tus usuarios.",
            "CONVERSATION_CONTRILLER": "Controlador de conversación:",
            "SOMETIMES_WHILE_THE_USER": "A veces mientras el usuario interactúa con tus plataformas hay factores externos que pueden interrumpir la venta efectiva o correcta recolección de datos, por eso el controlador de conversación se encargara de enviarle un mensaje amigable para recordarle e informarle al usuario que su tarea no ha sido completada, y así poder recuperar su interacción.",
            "PROMOTIONS_AND_UPDATES": "Promociones y actualizaciones:",
            "THESE_TYPES_OF_NOTIFICATIONS": "Este tipo de notificaciones funcionan para informar a los usuarios de nuevas funciones y oportunidades, ejemplo, un descuento de vacaciones o una prueba exclusiva.",
            "ADVANTAGES_OD_PUSH_NOTIFICATION": "Ventajas de las Push notification",
            "THEY_SERVE_TO_COMMUNICATE": "Sirven para comunicar novedades importantes para el usuario y la compañía, ejemplo, acerca de productos nuevos, promociones, lanzamientos y eventos.",
            "BEING_NOTIFICATIONS": "Al ser notificaciones generan la sensación de urgencia o alerta en los usuarios.",
            "SECRETS_TO_CREATING_A_GOOD": "Secretos para crear una buena estrategia de Push",
            "BY_FOLLOWING_THESE_TIPS": "Siguiendo estos consejos y dando un mensaje pensado en las necesidades de tu compañía podrás garantizar resultados satisfactorios y buenos puentes de comunicación con el usuario actual y futuros prospectos.",
            "CREATE_LONKS_AND_THINK": "Crea lazos y piensa los textos buscando fidelizar al lector.",
            "USE_LOTS_OF_COOL_ACTION": "Utiliza muchos verbos de acción y Calls to Action atractivos.",
            "USE_DATA_AND_STATISTICS": "Usa datos y estadísticas (¡comprobados!).",
            "CUSTOMIZE_YOUR_MESSAGE": "Personaliza tus mensajes para llamar la atención del lector.",
            "DO_NOT_TRY_TO_COMMUNICATE": "No intentes comunicar todo de una sola vez.",
            "CONSIDER_IT_AS_ONE_MORE": "Considerarla como una herramienta más dentro de un plan integral omnicanal.",
            "LET_YOURSELT_BE_SURPRISED": "Déjate sorprender y sorprende a tus",
            "USERS_WITH_THE_PUSH": "usuarios con las push.",
            "IN_DESTINY_WE_WILL_GIVE": "¡En Destiny te daremos los mejores resultados!",

            "THIS_IS_HOW_WORK": "¡Así trabajamos!",
            "1_COMPUTER_CONSULTING": "1.Consultoría informática",
            "WE_WILL_START_WITH_PLATFORM": "Comenzaremos con la consultoría de soluciones y plataformas para ayudar a nuestros clientes a obtener una visión clara de la solución en TI que desean implementar. También brindamos consultoría estratégica, asesorando a las empresas sobre cómo mejorar sus estrategias de TI.",
            "2_IMPLEMENTATION": " 2. Implementación",
            "WE_WILL_DEVELOP_AND_TEST": "Desarrollaremos y probaremos una solución de TI para ayudar a nuestros clientes en la integración, la migración de datos y otros procesos relacionados entre sí, garantizando de esta manera que la nueva solución brindada se integre en los flujos de trabajo corporativos y contribuya al valor comercial.",
            "3_SUPPORT": " 3. Apoyo",
            "WE_PROVIDE_COMPREHENSIVE_SUPPORT": "Brindamos soporte integral de las soluciones de TI que utilizan nuestros clientes, no importa si son ya existentes o creadas por nuestro propio equipo, agregando nuevas funciones, corrigiendo errores, garantizando una mejor interoperabilidad, rendimiento y usabilidad de las mismas.",
            "CHOOSE_THE_OPTION": "Elige la mejor opción enfocada a tus necesidades.",
            "ON_YOUR_NEEDS": "en sus necesidades.",
            "AT_DESTINY_WE_WILL_PROVIDE_YOU_WITH": "En Destiny te brindaremos la mejor asesoría desde el primer momento",
            "OF_CONTACT_THUS_GIVING_YOU_A_GUIDE": "de contacto, dándote así una guía y un portafolio amplio de opciones.",
            "WE_OFFER_YOU": "Te ofrecemos",
            "PROJECT_SUPPORT_SERVICES": "Servicios de soporte de Proyect.",
            "IMFORMATION_ARCHITECT_AND_MORE": "Arquitecto de información y más.",
            "TECHONOLOGY_EXPERTS": "Expertos en Tecnología:",
            "OUR_TEAM_HANDLES_a_WIDE": "Nuestro equipo maneja una variedad amplia de idiomas y recursos, que nos permiten siempre brindarle las mejores soluciones a nuestros clientes, en cualquier lugar del mundo.",
            "PARTNERS": "Partners",
            "DESTINY_HAS_AS_ALLIES_GREAT": "Destiny tiene como aliados grandes plataformas tecnológicas que nos permiten automatizar, administrar, enviar y crear comunicaciones eficientes para usted y sus clientes.",
            "CUSTOMERS": "Customers",
            "BY_CREATING_SOLUTIONS_AND_GENERATING": "Creando soluciones y generando un factor diferenciador en los productos de nuestros clientes hemos creado un importante portafolio de trabajo, formando así relaciones sólidas no solo entre Destiny y sus clientes sino también entre las compañías y sus usuarios.",

            "WHAT_ARE_CONSULTING_SERVICES": "¿Qué son los servicios de consultoría?",
            "CONSULTING_SERVICES_ALLOW_COMPANIES_TO": "Los servicios de consultoría permiten que las empresas implementen estrategias y soluciones en tecnología de la información para lograr la alineación entre el negocio y sus plataformas impulsando así el valor máximo de las iniciativas actuales.",
            "APUESTA_POR_UNA_COMUNICATION_RAPIDA": "Apuesta por una comunicación rápida y eficaz con el usuario, para que siempre consigas los mejores resultados en el menor tiempo estimado.",
            "TRUST_US": "¡Confía en nosotros!",
            "WE_HAVE_7_YEARS_OF": "Tenemos 7 años de experiencia en cada una de estas áreas.",
            "SOFTWARE": "Desarrollo de software.",
            "DEVELOPMENT": "Desarrollo de",
            "TECHNICAL_AND": "Soporte técnico y de",
            "APPLICATION_SUPPORT": "aplicaciones.",
            "INFRASTRUCTURE_MANAGEMENT": "Gestión de infraestructura.",
            "MANAGEMENT": "Gestión de",
            "CONSULTING_AND": "Consultoría y",
            "MIGRATION_IN_THE": "migración en la",
            "CLOUD": "nube.",
            "OUR_TEAM": "¡Nuestro equipo!",
            "WE_HAVE_A_GROUP_OF_SOFTWARE": "Contamos con un grupo de desarrolladores de software que cubren una variedad de pilas de tecnología,",
            "EXPERIENCE_AND_INTERFACE": "Diseñadores de experiencia e interfaz",
            "SOLUTION_ARCHITECTS": "Arquitectos de soluciones.",
            "PROFESSIONALS_IN_QUALITY": "Profesionales de control de calidad y otros roles.",
            "WE_ARE_EXPERTS": "¡Somos expertos!",
            "AT_DESTINY_WE_ARE_CERTIFIED": "En Destiny somos profesionales certificados en:",
            "PLATFORM_BASED_AND_CUSTOM": "Desarrollo de software personalizado y basado en plataforma.",
            "WEB_DEVELOPMENT": "Desarrollo web",
            "ANALISIS_OF_DATA": "Dnálisis de datos",
            "CYBERSECURITY_AND_MORE": "Ciberseguridad y más.",
            "INDUSTRIES_IN_WHICH_WE": "Industrias en las que incursionamos",
            "BANKS": "Bancos",
            "FOOD_CAMPANIES": "Empresas de alimentos,",
            "CLOTHING_BRANDS": " Marcas de ropa.",
            "INFORMATIVE": "Informativas.",
            "GOVERNMENT_ENTITIES_AND_MORE": "Entidades gubernamentales y más.",
            "WE_ACHIEVE_THE_OBJECTIVES": "Alcanzamos los objetivos de nuestros clientes",
            "STOP_THE_MACHINES": "¡Detengan las máquinas!",
            "WE_FOCUS_ALL_OUR": "Centramos toda nuestra atención en la etapa de asesoramiento para superar todos los posibles desafíos de TI, y así establecer una estrategia de transformación en la compañía.",
            "WE_OFFER_COMLATE_SERVICE": "Ofrecemos paquetes completos de servicio en asistencia para proyectos de TI. En los cuales planificamos, diseñamos, implementamos y apoyamos continuamente soluciones basadas en tecnología.",
            "KPI_PERFOMANCE_METERS": "KPI Medidores de desempeño",
            "WE_OFFER_35_REDUCTION_IN": "Ofrecemos el 35% de reducción en los costos operativos en TI.",
            "WE_HAVE_A_99_AVAILABILITY_OF": "Tenemos un 99% de disponibilidad del servicio.",
            "WE_GUARANTEE_A_50": "Garantizamos un 50% de reducción del tiempo medio para dar soluciones.",
            "WE_PROVIDE_96_IN": "Brindamos 96% en CSAT.",
            "AT_DESTINY_WE_HAVE_THE_TRUST": "En Destiny contamos con la confianza de clientes como",
            "WE_WANT_TO_WORK_WITH_YOU": "Queremos trabajar con usted y darle",
            "YOU_THE_BEST_ADVICE": "el mejor asesoramiento",
            "LETS_CREATE_TOGETHER": "para que así juntos alcancemos tus metas actuales y,",
            "AND_WHY_NOT": "¿por qué no?, creemos muchas nuevas.",

            "FOOTER_HOME": "Inicio",
            "FOOTER_ABOUT": "About",
            "FOOTER_WORK": "Work",
            "FOOTER_SERVICE": "Servicios",
            "FOOTER_PEOPLE": "Equipo",
            "FOOTER_FREELANCER": "Freelancer",
            "FOOTER_CONTACT": "Contacto",

            "BANNER_ABOUT": "Sobre Destiny",
            "BANNER_PEOPLE": "Equipo",


            "OUR_WORK": "Nuestro Trabajo",
            "PRO_QUE_TRABAJAR_CON": "¿Por qué trabajar con nosotros?",
            "WE_ARE_THE_PERFECT_COMPLEMENT_BETWEEN": "Somos el complemento perfecto entre la juventud y la experiencia. Actualmente contamos con más de",
            "IN_THE_MARKET_THUS_GUARANTEEING_THE_CORRECT_OPERATION": "en el mercado garantizando así el correcto funcionamiento y la adquisición de buenos resultados en cada uno de nuestros proyectos.",

            "WE_ARE_A_COMPANY_IN_CONSTANT_EVOLUTION": "¡Somos una empresa en constante evolución!",
            "WE_ARE_NOT_SATISFIED_WITH_THE_BASIC_OPRATION": "No nos conformamos con el funcionamiento básico de nuestros proyectos, buscamos siempre revolucionar e innovar con nuevas estrategias que marquen la diferencia.",
            "SECURITY_EXPERTS": "¡Expertos en seguridad!",
            "WE_WORK_REMOTALY_WITH_OUR_TEAMS_TO_PROVIDE": "Trabajamos de forma remota con nuestros equipos para brindar tranquilidad a nuestros clientes, garantizando siempre la estabilidad y seguridad de cada uno de sus productos.",
            "DESTINY_NEVER_SLEEPS": "¡Destiny nunca duerme!",
            "WE_WORK_24_HOURS_A_DAY_FOR_OUR_CUSTOMERS": "Trabajamos 24 horas para nuestros clientes, brindando así un soporte constante y soluciones inmediatas. No importa donde te encuentres siempre encontrarás apoyo de nuestros profesionales",

            "CORPORATE_VALUES": "Valores Corporativos",
            "WE_BELIEVE_IN_CREATIVE_MIND_IN_THE_MAGIC_OF": "Creemos en las mentes creativas, en la magia de incluir nuevas culturas y personalidades en nuestro equipo, ver innovar a nuestros jóvenes todos los días y valorar la experiencia de aquellos que crearon desde hace algún tiempo la confianza en la compañía, nos motiva aprender cada día de nuestros compañeros y sus diferentes perspectivas, llegando siempre a la misma meta corporativa, la integridad siempre va por encima de cualquier expectativa.",
            "CREATIVITY": "Creatividad",
            "INCUSION": "Inclusión",
            "INNOCATION": "Innovación",
            "TRUST": "Confianza",
            "FELLOWSHIP": "Compañerismo",
            "INTEGRITY": "Integridad",

            "WORK_WITH_US": "Trabaja con Nosotros",
            "DESTINY_WANTS_IN_ITS_TEAM_PEOPLE_WHO_ARE_AN": " Destiny quiere en su equipo personas que hagan parte importante de la compañía, porque somos un lugar diverso y versátil, un mundo joven lleno de experiencia, en el cual todas las competencias, conocimientos y experiencias tienen un valor incalculable. Llevamos 7 años creciendo y aprendiendo y hoy no vamos a parar. Nuestro destino y nuestro sentido es cumplir sueños y objetivos, por eso nunca dudes en unirte a un equipo que día a día trabajará para cumplir tus metas profesionales y sueños. Somos un equipo lleno de actitud y motivación, y esperamos trabajar con personas como tu que no encuentren límites en su camino.",
            "WE_HAVE_3_OFFICES_NO_MATTER_WHERE_YOU_ARE": "Contamos con 3 oficinas, no importa donde te encuentres y cual sea tu experticia; Destiny celebra todas las metas obtenidas, porque la inspiración y motivación de nuestro equipo hace que logremos todos nuestros objetivos. Arriésgate a motivar y a ser valorado en tu entorno de trabajo.",

            "PEOPLE_FORM": "Formulario",
            "PEOPLE_NAME": "Nombres",
            "PEOPLE_LAST_NAME": "Apellido",
            "PEOPLE_EMAIL_ADDRESS": "Correo electrónico",
            "PEOPLE_CONTACT_NAMUBER": "Número de contacto",
            "PEOPLE_YOU_WANT_TI_ADD_A_COMMENT": "Deseas agregar un comentario..",
            "PEOPLE_CURRICULUM": "Hoja de vida",
            "PEOPLE_DROP_THE_FILE_TO_LOAD": "Suelta el archivo a cargar",
            "PEOPLE_JOIN_NOW": "Únete ahora",

            "QUOTE_HERE": "Cotiza aquí",

            "REQUEST_FOR_QUOTATION": "Solicitud de Cotización",
            "QUOTATION_FULL_NAME": "Nombres completos*",
            "QUOTATION_EMAIL_ADDRESS": "Correo electrónico*",
            "QUOTATION_CONTACT_NUMBER": "Número de contacto*",
            "QUOTATION_SELECT_A_SERVICE": "Selecciona un servicio*",
            "QUOTATION_BUGGEET_PROJECT": "Selecciona el presupuesto de tu proyecto*",
            "QUOTATION_DATE_QUOTATION": "Selecciona la fecha de tu cotización",
            "QUOTATION_DETAILS": "Escribe más detalles de tu proyecto…",
            "QUOTATION_SEND_REQUEST": "Enviar solicitud",

            "QUOTATION_FULL_NAME_REQUIRED": "Por favor proporcione el nombre",
            "QUOTATION_EMAIL_ADDRESS_REQUIRED": "Campo requerido",
            "QUOTATION_CONTACT_NUMBER_REQUIRED": "Número de contacto*",
            "QUOTATION_SELECT_A_SERVICE_REQUIRED": "Selecciona un servicio",
            "QUOTATION_BUGGEET_PROJECT_REQUIRED": "Selecciona el presupuesto de tu proyecto",
            "QUOTATION_DATE_QUOTATION_REQUIRED": "Por favor proporcione la fecha",

            "PEOPLE_FIRST_NAME_REQUIRED": "Por favor proporcione el nombre",
            "PEOPLE_LAST_NAME_REQUIRED": "Por favor proporcione el nombre",
            "PEOPLE_EMAIL_REQUIRED": "Campo requerido",
            "PEOPLE_CONTACT_REQUIRED": "Número de contacto requerido",
            "PEOPLE_MESSAGE_REQUIRED": "Escribe más detalles de tu proyecto",
            "PEOPLE_UPLOAD_DOCUMENT_REQUIRED": "Escribe más detalles de tu proyecto",

            "BET_ON_A_FAST_AND_EFFICIENT_COMMUNICATION": "Apuesta por una comunicación rápida y eficaz con el usuario, para que siempre consigas los mejores resultados en el menor tiempo estimado.",
            "SCHEDULE_A_FREE_CONSULTATION": "Programe una consulta gratuita",
            "HERE": "Aquí",
            "DIFFERENTIATING_FACTOR": "Factor Diferenciador",
            "SOCIAL_MEDIA": "Redes Sociales",
            "WE_HAVE_SUCCESEFULLY_RECEIVED_YOUR": "Hemos recibido su hoja de vida con éxito. El responsable del área se pondrá en contacto con usted."
        }
    }



    console.log("arrLang  ====?", arrLang);

    $('.lang').each(function (index, element) {
        $(this).text(arrLang[lang][$(this).attr('key')]);



        if ('QUOTATION_CONTACT_NUMBER' == $(this).attr('key')) {
            $('#quotation_number_contact').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }

        if ('QUOTATION_SELECT_A_SERVICE' == $(this).attr('key')) {
            $('.service-purchase').select2({
                placeholder: arrLang[lang][$(this).attr('key')],
                allowClear: true,
                minimumResultsForSearch: Infinity,
            })
        }

        if ('QUOTATION_BUGGEET_PROJECT' == $(this).attr('key')) {
            $('.service-purchase-second').select2({
                placeholder: arrLang[lang][$(this).attr('key')],
                allowClear: true,
                minimumResultsForSearch: Infinity,
            })
        }
        if ('QUOTATION_DATE_QUOTATION' == $(this).attr('key')) {
            $('#quotation_project_date').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }



        if ('NAME' == $(this).attr('key')) {
            $('#firstName1').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('EMAIL' == $(this).attr('key')) {
            $('#email1').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('PHONE' == $(this).attr('key')) {
            $('#phone1').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('CONTACT_AREA' == $(this).attr('key')) {
            $('.contact-area').select2({
                placeholder: arrLang[lang][$(this).attr('key')],
                allowClear: true,
                minimumResultsForSearch: Infinity,
            })
        }
        if ('MESSAGE' == $(this).attr('key')) {
            $('#message1').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('SEARCH_ASSETS' == $(this).attr('key')) {
            $('#mySearch').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }



        if ('YOUR_FIRST_NAME' == $(this).attr('key')) {
            $('#firstName').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('YOUR_LAST_NAME' == $(this).attr('key')) {
            $('#lastName').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('NAME_GEMAIL' == $(this).attr('key')) {
            $('#email').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('YOUR_COMPANY_NAME' == $(this).attr('key')) {
            $('#companyName').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('ACCEPT_AND_CONTINUE' == $(this).attr('key')) {
            $('#btnCommercialUser').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }

        if ('YOUR_FIRST_NAME' == $(this).attr('key')) {
            $('#frstName').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }
        if ('YOUR_LAST_NAME' == $(this).attr('key')) {
            $('#laName').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }

        if ('PLEASE_SELECT_COUNTRY' == $(this).attr('key')) {
            $('#country').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }

        if ('EMAIL' == $(this).attr('key')) {
            $('#femail').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }

        if ('JOB_DESCRIPTION' == $(this).attr('key')) {
            $('#jobdescription').attr('placeholder', arrLang[lang][$(this).attr('key')]);
        }


    });


    setTimeout(function () {
        $('.lang').each(function (index, element) {
            $(this).text(arrLang[lang][$(this).attr('key')]);

            if ('BUY_ALL' == $(this).attr('key')) {
                $('#BUY_ALL').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }
            if ('OPTIONS' == $(this).attr('key')) {
                $('#OPTIONS').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }
            if ('BUY_BUTTON' == $(this).attr('key')) {
                $('.BUY_BUTTON').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }
            if ('VIEW_DETAILS' == $(this).attr('key')) {
                $('.VIEW_DETAILS').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }
            if ('TOTAL_QUANTITY' == $(this).attr('key')) {
                $('.TOTAL_QUANTITY').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }
            if ('ESTIMATED_TIME_AND_COST' == $(this).attr('key')) {
                $('.ESTIMATED_TIME_AND_COST').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }
            if ('VIEW_ALL' == $(this).attr('key')) {
                $('.VIEW_ALL').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }

            if ('STARTING_FROM' == $(this).attr('key')) {
                $('.STARTING_FROM').attr('placeholder', arrLang[lang][$(this).attr('key')]);
            }




        });
    }, 2000)



}




function sendContectEmail(obj) {
    var sendEmailData = {};
    if (obj.title == 'ME') {
        sendEmailData = {
            variables: {
                url: gerateDestinyURL() + 'people.html'
            },
            to_email: obj.contactArea
        }
    } else if (obj.title == 'YOUR') {
        sendEmailData = {
            variables: {
                name: obj.name,
                email: obj.email,
                mobile: obj.mobile,
                message: obj.message,
                ourcontactarea: obj.ourcontactarea
            },
            to_email: obj.contactArea
        }

    } else if (obj.title == 'YOURSERVICES') {
        sendEmailData = {
            variables: {
                name: obj.name,
                email: obj.email,
                mobile: obj.mobile,
                message: obj.message,
                service: obj.service,
                budget: obj.budget,
                date: obj.date
            },
            to_email: obj.contactArea
        }

    } else if (obj.title == 'PEOPLEMODAL') {
        sendEmailData = {
            variables: {
                fname: obj.fname,
                lname: obj.lname,
                email: obj.email,
                mobile: obj.mobile,
                message: obj.message,
                pdfUrl: obj.pdfUrl
            },
            to_email: obj.contactArea
        }
    } else if (obj.title == 'MARKETINGMODALME') {
        sendEmailData = {
            variables: {
                marketingFullName: obj.marketingFullName,
                contentPart: obj.contentPart
            },
            to_email: obj.marketingEmailArr
        }
    } else if (obj.title == 'MARKETINGMODAL') {
        sendEmailData = {
            variables: {
                marketingName: obj.marketingName,
                marketingEmail: obj.marketingEmail,
                marketingMobile: obj.marketingMobile,
                marketingProfesion: obj.marketingProfesion,
                subjectPart: obj.subjectPart
            },
            to_email: obj.marketingEmailArr
        }
    }
    jQuery.ajax({
        url: generateAPIURL() + '/contactEmailSend',
        dataType: 'json',
        data: JSON.stringify(sendEmailData),
        type: 'POST',
        headers: generateHeader(),
        success: function (userResponse) {
            console.log("userResponse ==> ", userResponse);
            console.log("sendEmailData ===?", sendEmailData);
            document.getElementById('firstName1').value = '';
            document.getElementById('email1').value = '';
            document.getElementById('phone1').value = '';
            document.getElementById('message1').value = '';
            $('#contactArea').select2('val', '');
        },
        error: function (userError) {
            console.log('userError ==> ', userError);
        }
    });
}

function sendOTP() {

    $.ajax({
        url: mainObj.AMPLIFY_API,
        type: "POST",
        headers: amplifyHeader('CUSTOM_AUTH'),
        dataType: "json",
        data: JSON.stringify({

            "AuthFlow": "CUSTOM_AUTH",
            "ClientId": mainObj.client_id,
            "AuthParameters": {
                "USERNAME": userInfo.e
            },
            "ClientMetadata": {}

        }),
        success: function (data) {
            console.log(data.Session)
            userInfo.OTPDetail.sessionID = data.Session;
            showSuccess();
            timerSet(150);
        },
        error: function (dataError) { console.log(dataError) }
    });
}


function checkValidation(check = "all") {
    userInfo.fn = $('#firstName').val();
    userInfo.sn = $('#lastName').val();
    userInfo.e = $('#email').val();
    userInfo.com = $('#companyName').val();
    userInfo.dob = formatDate(new Date());
    userInfo.udd = $('#deliveryDate').val();
    userInfo.udt = $('#deliveryTime').val();
    userInfo.pn = $('#com_phone').val();

    var validated = false;
    if (check === "all" || check === "firstname-require-validation") {
        let companyName = $('#firstName').val() + " " + $('#lastName').val();
        $('#companyName').val(companyName);
        if (!userInfo.fn) {
            $("#firstname-require-validation").show();
            validated = true;
        } else {
            $("#firstname-require-validation").hide();
        }
    }

    if (check === "all" || check === "lastname-require-validation") {
        let companyName = $('#firstName').val() + " " + $('#lastName').val();
        $('#companyName').val(companyName);
        if (!userInfo.sn) {
            $("#lastname-require-validation").show();
            validated = true;
        } else {
            userInfo.com = userInfo.fn + " " + userInfo.sn;
            $("#lastname-require-validation").hide();
        }
    }

    if (check === "all" || check === "email-require-validation") {
        if (!userInfo.e) {
            $("#email-require-validation").show();
            validated = true;
        } else {
            $("#email-require-validation").hide();
        }
    }

    if (check === "all" || check === "email-valid-validation") {
        if (userInfo.e && !validateEmail(userInfo.e)) {
            $("#email-valid-validation").show();
            validated = true;
        } else {
            $("#email-valid-validation").hide();
        }
    }

    if (check === "all" || check === "date-require-validation") {
        if (!userInfo.udd) {
            $("#date-require-validation").show();
            validated = true;
        } else {
            $("#date-require-validation").hide();

            let currentDate = $('#deliveryDate').val();
            if (new Date(mainObj.calculatedDeliveryDate) > new Date(currentDate)) {
                $("#mindate-warning-msg").show();
                validated = true;
            } else {
                $("#mindate-warning-msg").hide();
            }
        }
    }

    if (check === "all" || check === "com-phone-require-validation") {
        if (userInfo.pn == '' || isNaN(userInfo.pn)) {
            $('#com-phone-require-validation').show();
            validated = true;
        } else {
            $("#com-phone-require-validation").hide();
        }
    }

    return validated;
}

function createCampaign() {
    if (checkuserlogin()) {
        console.log("userInfo ==> ", userInfo);
        if (userInfo.spid && userInfo.spid.length && userInfo.udd) {
            var campaignData = {
                type: 'CreateProject',
                campaign: {
                    accepted_campiagn_date: userInfo.udd, //userInfo.udd
                },
                leadtypeId: userInfo.spid,
                user_id: parseInt(getCookie('uid')),
                slug: userInfo.s,
                brand: {
                    id: getCookie('bid'),
                    partner_id: getCookie('cid')
                }
            }
            console.log("Campaign ==> creating => ", campaignData);
            $('#msg2').css('display', 'flex');
            $('#msg2_1').css('display', 'block');
            $('#msg2_2').css('display', 'none');
            jQuery.ajax({
                url: generateAPIURL() + '/project',
                dataType: 'json',
                data: JSON.stringify(campaignData),
                type: 'POST',
                headers: generateHeader(),
                success: function (campaignResponse) {
                    console.log("Campaign ==> Done ==> Result ==> ", campaignResponse);
                    if (campaignResponse && campaignResponse.data && campaignResponse.data.result && campaignResponse.data.result.campaignId && campaignResponse.data.result.campaignId > 0) {
                        userInfo.campaign = campaignResponse.data.result;
                        userInfo.campaign.soid = 0;
                        userInfo.campaign.campaignId = campaignResponse.data.result.campaignId;
                        $('#msg2_1').css('display', 'none');
                        $('#msg2_2').css('display', 'block');
                        createOrder();
                    } else {
                        console.log('Campaign ==> Err ==> ');
                        toastr.error("Error while creating project");
                        $("#sidePanelLoader").css('display', 'none');
                    }
                },
                error: function (campaignError) {
                    console.log('Campaign ==> Err ==> ', campaignError);
                    if (campaignError.status === 500 && campaignError.responseJSON.error_type == "timeout") {
                        webSocket(campaignError.responseJSON.data).then((campaignResponse) => {
                            if (campaignResponse && campaignResponse.result && campaignResponse.result.campaignId && campaignResponse.result.campaignId > 0) {
                                userInfo.campaign = campaignResponse.result;
                                userInfo.campaign.soid = 0;
                                userInfo.campaign.campaignId = campaignResponse.result.campaignId;
                                $('#msg2_1').css('display', 'none');
                                $('#msg2_2').css('display', 'block');
                                createOrder();
                            }
                        }, (err) => {
                            toastr.error("Error while creating project");
                            $("#sidePanelLoader").css('display', 'none');
                            console.log("createCampaign webSocket err ===>", err);
                        });
                    }
                }
            });
        } else {
            console.log('Campaign ==> Err ==> select package');
        }
    } else {
        toastr.error("Please register your account...");
        $("#sidePanelLoader").css('display', 'none');
        console.log("Campaign ==> need login to continue...");
    }
}

function createOrder() {
    if (checkuserlogin()) {
        if (userInfo && userInfo.campaign && userInfo.campaign.campaignId && userInfo.campaign.lead_line_ids && userInfo.campaign.lead_line_ids.length > 0) {
            var orderData = {
                type: 'CreateSaleOrder',
                campaignId: userInfo.campaign.campaignId,
                lead_line_ids: userInfo.campaign.lead_line_ids,
                user_id: getCookie('uid'),
                slug: userInfo.s
            }
            console.log("Order ==> creating => ", orderData);
            $('#msg3').css('display', 'flex');
            $('#msg3_1').css('display', 'block');
            $('#msg3_2').css('display', 'none');
            jQuery.ajax({
                url: generateAPIURL() + '/create-order',
                dataType: 'json',
                data: JSON.stringify(orderData),
                type: 'POST',
                headers: generateHeader(),
                success: function (orderResponse) {
                    console.log("Order ==> Done ==> Result ==> ", orderResponse);
                    if (orderResponse && orderResponse.data && orderResponse.data.result && orderResponse.data.result.length > 0 && orderResponse.data.result[0].id > 0) {
                        userInfo.campaign.soid = orderResponse.data.result[0].id;
                        userInfo.campaign.product_template_firstid = orderResponse.data.result[0].product_template_ids[0];
                        userInfo.campaign.quotation_number = orderResponse.data.result[0].quotation_number;
                        $('#msg3_1').css('display', 'none');
                        $('#msg3_2').css('display', 'block');
                        autoTask();
                    } else {
                        console.log('Order ==> Err ==> ');
                        toastr.error("Error while creating order");
                        $("#sidePanelLoader").css('display', 'none');
                    }
                },
                error: function (orderError) {
                    if (orderError.status === 500 && orderError.responseJSON.error_type == "timeout") {
                        webSocket(orderError.responseJSON.data).then((orderResponse) => {
                            if (orderResponse && orderResponse.result && orderResponse.result.length > 0 && orderResponse.result[0].id > 0) {
                                userInfo.campaign.soid = orderResponse.result[0].id;
                                userInfo.campaign.product_template_firstid = orderResponse.result[0].product_template_ids[0];
                                userInfo.campaign.quotation_number = orderResponse.result[0].quotation_number;
                                $('#msg3_1').css('display', 'none');
                                $('#msg3_2').css('display', 'block');
                                autoTask();
                            }
                        }, (err) => {
                            toastr.error("Error while creating order");
                            $("#sidePanelLoader").css('display', 'none');
                            console.log("createOrder webSocket err ===>", err);
                        });
                    }
                }
            });
        } else {
            console.log('Order ==> Err ==> Need campaign');
        }
    } else {
        console.log("Order ==> need login to continue...");
    }
}

function autoTask() {
    if (checkuserlogin()) {
        if (userInfo && userInfo.campaign && userInfo.campaign.campaignId && userInfo.campaign.campaignId > 0 && userInfo.campaign.soid && userInfo.campaign.soid > 0) {
            // var autoTaskData = {
            //     type: 'ConfirmSaleOrder',
            //     campaignId: userInfo.campaign.campaignId,
            //     saleOrderID: userInfo.campaign.soid,
            //     user_id: getCookie('uid'),
            //     slug: userInfo.s,
            // }
            var autoTaskData = {
                "args": [
                  userInfo.campaign.soid,
                ]
              }
            console.log("AutoTask ==> creating => ", autoTaskData);

            $('#msg4').css('display', 'flex');
            $('#msg4_1').css('display', 'block');
            $('#msg4_2').css('display', 'none');
            jQuery.ajax({
                // url: generateAPIURL() + '/autotask',
                url: generateAPIURL() + '/saleorderconfirm',
                dataType: 'json',
                data: JSON.stringify(autoTaskData),
                type: 'POST',
                headers: generateHeader(),
                success: function (autoTaskResponse) {
                    console.log("AutoTask ==> Done ==> Result ==> ", autoTaskResponse);
                    if (autoTaskResponse) {
                        $('#msg4_1').css('display', 'none');
                        $('#msg4_2').css('display', 'block');
                        products();
                    } else {
                        console.log('AutoTask ==> Err ==> ');
                    }
                },
                error: function (autoTaskError) {
                    if (autoTaskError.status === 500 && autoTaskError.responseJSON.error_type == "timeout") {
                        webSocket(autoTaskError.responseJSON.data).then((res) => {
                            console.log('webSocket ==> res ==> ', res);
                            let autoTaskResponse = JSON.parse(res);
                            if (autoTaskResponse.result) {
                                $('#msg4_1').css('display', 'none');
                                $('#msg4_2').css('display', 'block');
                                products();
                            }
                        }, (err) => {
                            console.log('webSocket ==> err ==> ', err);
                            toastr.error("Error while creating task");
                            $("#sidePanelLoader").css('display', 'none');
                            console.log("err ===>", err);
                        });
                    }
                }
            });
            // products();
        } else {
            toastr.error("Campaign and sale order not found");
            $("#sidePanelLoader").css('display', 'none');
            console.log('AutoTask ==> Err ==> Need campaign and saleorder');
        }
    } else {
        console.log("AutoTask ==> need login to continue...");
    }
}

function products() {
    if (checkuserlogin()) {
        if (userInfo && userInfo.campaign && userInfo.campaign.campaignId && userInfo.campaign.campaignId > 0) {
            var productData = {
                type: 'GetMrpProductionDetails',
                campaignId: userInfo.campaign.campaignId, // static
                user_id: getCookie('uid'),
                slug: userInfo.s,
                brand: {
                    id: getCookie('bid'),
                    partner_id: getCookie('cid')
                },
                rpp: 100,
                page: 1,
            }
            console.log("Products ==> creating => ", productData);

            jQuery.ajax({
                url: generateAPIURL() + '/products',
                dataType: 'json',
                data: JSON.stringify(productData),
                type: 'POST',
                headers: generateHeader(),
                success: function (productResponse) {
                    if (productResponse) {
                        $("#sidePanelLoader").css('display', 'none');
                        removeuserCookieData();
                        removeCartData();
                        $("#userData").hide();
                        $("#summaryData").hide();
                        $('#paymentData').css('display', 'none');
                        $("#thankyou").css('display', 'block');
                        var brefer = mainObj.defaultBrandreference; //userInfo.brand_referance
                        var camID = userInfo.campaign.campaignId
                        var qNum = userInfo.campaign.quotation_number;
                        var prodTempId = userInfo.campaign.product_template_firstid;
                        var moID = productResponse.data.result.mo_orders[0].id;

                        var trackurl = generateTrackURL() + brefer + '/' + camID + '/quotationId/' + qNum + '/module-list/' + prodTempId + '/task-list-config/' + moID;

                        $("#linkurl").append("You will be redirected to <a href='javascript:void(0);' onclick='javascript:redirectUser(\"" + trackurl + "\")'><b>W</b>-Track</a> to continue with your Quotation");

                        redirectUser(trackurl, 'webpage');
                        userInfo.brand_referance = mainObj.defaultBrandreference; //'';
                        userInfo.campaign.campaignId = 0;
                        userInfo.campaign.quotation_number = 0;
                        userInfo.campaign.product_template_firstid = 0;

                        // setTimeout(function () {
                        //   userInfo.brand_referance = '';
                        //   userInfo.campaign.campaignId = 0;
                        //   userInfo.campaign.quotation_number = 0;
                        //   userInfo.campaign.product_template_firstid = 0;

                        //   $("#linkurl").hide();
                        //     // window.location=trackurl;
                        //     closeSidebar1();
                        //     $("#thankyou").css('display','none');
                        //     redirectUser(trackurl,'webpage');
                        //  }, 1000);
                        console.log('PRODUCT-------', productResponse.data.result);
                        let moHtml = '';
                        // if (productResponse.data && productResponse.data.result && productResponse.data.result.mo_orders && productResponse.data.result.mo_orders.length > 0) {
                        //     productResponse.data.result.mo_orders.forEach(mo => {
                        //         let price = '';
                        //         status = mo.status !== 'Not Assigned' ? 'Assigned' : mo.status;
                        //         const package = packageListArr.find(p => p.id === userInfo.pid);
                        //         if (package && package.Child && package.Child.length > 0) {
                        //             const subPackage = package.Child.find(sp => sp.id === userInfo.spid);
                        //             if (subPackage && subPackage.preAssetList && subPackage.preAssetList.length > 0) {
                        //                 const asset = subPackage.preAssetList.find(pa => pa.product_id && pa.product_id[0] && pa.product_id[0] === mo.product_id);
                        //                 if (asset) {
                        //                     price = asset.public_price;
                        //                 }
                        //             }
                        //         }
                        //         moHtml += `
                        //             <tr id="mo_${mo.id}">
                        //                 <td scope="col">${mo.product_name}</td>
                        //                 <td scope="col">${status}</td>
                        //                 <td scope="col">${moment(mo.date_planned_start).format("Do MMM, YYYY h:mm:ss A")}</td>
                        //                 <td scope="col">${moment(mo.date_planned_finished).format("Do MMM, YYYY h:mm:ss A")}</td>
                        //                 <td scope="col">${price}</td>
                        //                 <td scope="col">
                        //                     <a href="javascript:void(0)" class="down-arrow" onclick="tasks(${mo.id})"><i id="viewData${mo.id}"class="fa fa-angle-down" aria-hidden="true"></i></a>
                        //                 </td>
                        //             </tr>
                        //             `;
                        //     });
                        // }
                        // $("#sidePanelLoader").css('display', 'none');
                        // $('#userData').css('display', 'none');
                        // $('#summaryData').css('display', 'none');
                        // $("#assetSummary").html(moHtml);
                        // $("#thankyou").css('display','block');
                        // $("#flow2").append(`
                        // <div class="footer-left">
                        // <div class="total-value d-flex">
                        //     Total:<span>` + userInfo.total_hour + `</span> Hrs / <span>` + userInfo.total_price + `</span>USD
                        // </div>
                        // <a href="javascript:void(0);" class="text-link">View Terms & Conditions</a>
                        // </div>				
                        // <a href="javascript:void(0);" class="btn btn-skyblue" onclick="javascript:paymentData();">Save and Continue</a>`);


                    } else {
                        toastr.error("Error while getting product");
                        $("#sidePanelLoader").css('display', 'none');
                        console.log('Products ==> Err ==> ');
                    }
                },
                error: function (productsError) {
                    toastr.error("Error while getting product");
                    $("#sidePanelLoader").css('display', 'none');
                    console.log('Products ==> Err ==> ', productsError);
                }
            });
        } else {
            console.log('Products ==> Err ==> Need campaign');
        }
    } else {
        console.log("Products ==> need login to continue...");
    }
}

function tasks(mo_asset_id) {
    var clicked = '';
    clicked = $("#viewData" + mo_asset_id).hasClass("fa-angle-up");

    if (clicked === true) {
        $("#viewData" + mo_asset_id).removeClass('fa-angle-up');
        $("#viewData" + mo_asset_id).addClass('fa-angle-down');
    } else {
        $("#viewData" + mo_asset_id).addClass('fa-angle-up');
        $("#viewData" + mo_asset_id).removeClass('fa-angle-down')
        $("#sidePanelLoader").css('display', 'flex');
    }



    if (checkuserlogin()) {
        console.log("length ===>", $(".task_" + mo_asset_id).length);
        if ($(".task_" + mo_asset_id).length === 0) {
            if (userInfo && userInfo.campaign && userInfo.campaign.campaignId && userInfo.campaign.campaignId > 0) {
                var taskParam = {
                    type: 'GetMrpProductionTasks',
                    campaignId: userInfo.campaign.campaignId, // static
                    user_id: getCookie('uid'),
                    slug: userInfo.s,
                    mo_asset_id: mo_asset_id
                }
                console.log("Tasks Param => ", taskParam);

                jQuery.ajax({
                    url: generateAPIURL() + '/tasks',
                    dataType: 'json',
                    data: JSON.stringify(taskParam),
                    type: 'POST',
                    headers: generateHeader(),
                    success: function (tasksResponse) {
                        console.log('tasksResponse ===>', tasksResponse);
                        if (tasksResponse) {
                            let taskHtml = '';
                            taskHtml += `
                            <tr class="task_${mo_asset_id}">
                            <td colspan="6" class="p-0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">TASK NAME</th>
                                        <th scope="col">ASSIGN</th>
                                        <th scope="col">START DATE</th>
                                        <th scope="col">FINAL DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                            `;
                            if (tasksResponse.data && tasksResponse.data.result && tasksResponse.data.result.task_list && tasksResponse.data.result.task_list.length > 0) {
                                tasksResponse.data.result.task_list.forEach(task => {
                                    taskHtml += `
                                        <tr>
                                            <td scope="col">${task.task_name}</td>
                                            <td scope="col">${(task.assignedto.name) ? task.assignedto.name : '-'}</td>
                                            <td scope="col">${(task.date_planned_start) ? task.date_planned_start : '-'}</td>
                                            <td scope="col">${(task.date_planned_finished) ? task.date_planned_finished : '-'}</td>
                                        </tr>`;
                                });
                            } else {
                                taskHtml += `
                                        <tr>
                                            <td class="text-center" colspan="4"><b>DATA NOT AVAILABLE</b></td>
                                        </tr>`;
                            }
                            taskHtml += `
                            </td>
                            </tr>
                                    </tbody>
                            </table>
                            `;

                            console.log('taskHtml ===>', taskHtml);
                            // $("#task_" + mo_asset_id).html(taskHtml);
                            // $("#task_" + mo_asset_id).show();
                            $("#sidePanelLoader").css('display', 'none');
                            $("#mo_" + mo_asset_id).closest("tr").after(taskHtml);
                            $("#mo_" + mo_asset_id).find('td:last').addClass('active');

                        } else {
                            console.log('Products ==> Err ==> ');
                        }
                    },
                    error: function (productsError) {
                        toastr.error("Error while getting task");
                        $("#sidePanelLoader").css('display', 'none');
                        console.log('Products ==> Err ==> ', productsError);
                    }
                });
            } else {
                console.log('Products ==> Err ==> Need campaign');
            }
        } else {
            console.log('Remove');
            $(".task_" + mo_asset_id).remove();
            $("#mo_" + mo_asset_id).find('td:last').removeClass('active');
        }
    } else {
        console.log("Products ==> need login to continue...");
    }
}


function paymentData() {
    $('#summaryData').css('display', 'none');
    $('#paymentData').css('display', 'block');

    $("#flow3").append(`
    <div class="footer-left">
    <div class="total-value d-flex">
        Total:<span>` + userInfo.total_hour + `</span> Hrs / <span>` + currancyFormat(userInfo.total_price) + `</span>USD
    </div>
    <a href="javascript:void(0);" class="text-link">View Terms & Conditions</a>
    </div>				
    <a href="javascript:void(0);" class="btn btn-skyblue" onclick="javascript:addncheckOut();">Pay and Continue</a>`);
}

function RedirectToPlan() {
    closeSidebar1();
    $("#alreadyRegister").css('display', 'none');
    var planUrl = mainObj.PLAN_URL;
    var envPr = '';
    if (mainObj.env_type == 'stg-') {
        envPr = 'staging-';
    } else if (mainObj.env_type == 'dev-') {
        envPr = 'development-';
    } else {
        envPr = '';
    }
    planUrl = planUrl.replace('ENV', envPr);
    var planurl = planUrl + mainObj.defaultBrandreference + '/campaign/crear-commercial/' + userInfo.pid[0] + '/' + userInfo.spid[0];
    console.log("planurl", planurl);
    window.location.href = planurl;
}

function timerSet(timeInSecond) {
    document.getElementById('btnContinue').disabled = false;
    document.getElementById('btnResend').disabled = true;
    document.getElementById("otpSent").innerHTML = "";
    var downloadTimer = setInterval(function () {
        if (timeInSecond <= 0) {
            clearInterval(downloadTimer);
            document.getElementById("countdown").innerHTML = "";
            document.getElementById('btnResend').disabled = false;
            document.getElementById('btnContinue').disabled = true;
            document.getElementById("otpSent").innerHTML = "OTP is expired. Please click Resend OTP button to get new OTP code";
        } else {
            document.getElementById('btnResend').disabled = true;
            document.getElementById('btnContinue').disabled = false;
            document.getElementById("countdown").innerHTML = `OTP expires in ${timeInSecond} seconds`;
        }
        timeInSecond -= 1;
    }, 1000);
}

function showSuccess() {
    let timeInSecond = 3;
    let successMessage = setInterval(function () {
        if (timeInSecond <= 0) {
            clearInterval(successMessage);
            document.getElementById("otpSent").innerHTML = "";
        } else {
            document.getElementById("otpSent").innerHTML = `OTP Sent to your Email Address.`;
        }
        timeInSecond -= 1;
    }, 1000);
}